import { useState, useEffect } from "react"
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { LostConnection } from '../../constant/index';
import { usePagination } from '../pagination/usePagination';


export const useNameCompany = () => {

    const [companies, setCompanies] = useState([]);
    const [informationCompany, setInformationCompany] = useState({});
    //GRAPHS PIE
    const [serie2, setSerie2] = useState([]);
    const [option, setOption] = useState({});
    //GRAPHS DONUT 
    const [serie3, setSerie3] = useState([]);
    const [option3, setOption3] = useState({});
    //modal Price Agreement
    const [saldoTotal, setSaldoTotal] = useState(0);
    const [agreement, setAgremment] = useState("");
    const [balancePaid, setBalancePaid] = useState(0);
    const [total, setTotal] = useState(0);
    const [pdf, setPdf] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [pricepaymet, setPricepaymet] = useState([]);
    //component Modal
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [method, setMethod] = useState("");
    //graphs 
    const [series, setSeries] = useState([]);
    //DATE
    const [createSweet] = useSweetAlert();
    const history = useHistory();
    //DetailAgreement
    const [listAgreement, setListAgreement] = useState([]);
    //TRANSACTION 
    const [transaction, setTransaction] = useState([]);
    const [cardName, setCardName] = useState("")
    const [cardPeriodos, setCardPeriodos] = useState("");
    const [cardTotal, setCardTotal] = useState(0);

    const [agreementsCompany, setAgreementsCompany] = useState([]);
    const [periodsAgreement, setPeriodsAgreement] = useState([]);

    const [idAgreementCompany, setIdAgreementCompany] = useState(0);
    const [idPeriodCompany, setIdPeriodCompany] = useState(0);


    const toggle = () => {
        setModal(!modal);
    }

    useEffect(() => {

        getCompanies();


    }, [history.location]);

    //FORM COMPANY
    const [formAgreement, handleInputChangeAgreement, resetAgreement, handleUpdateValuesAgreements, handleSelectValuesAgreements] = useForm({
        id_company: 0,
        id_agreement: 0,
        amount: 0
    });

    const {
        id_company, id_agreement, amount
    } = formAgreement;


    const getCompanies = async () => {
        let requestOptions = {
            method: 'GET'
        };

        let listCompanies = [];

        const respuesta = await sendRequest(requestOptions, "Company/List");

        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {

                respuesta.data.forEach((obj) => {
                    listCompanies.push({
                        label: obj.code + " " + obj.name + " " + obj.rfc,
                        value: obj.id_company
                    });
                });

                setCompanies(listCompanies);
            }
            else {
                setCompanies([]);
            }
        }

        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getCompanies);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cobranza por cliente", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Cobranza por cliente", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     validarSesion(history, respuesta.code, getCompanies);
        // }
    }


    const handleSelectCompany = (e, name_input) => {
        handleSelectValuesAgreements(e, name_input);

        if (e !== null) {
            getInformationCompany(e.value);
            GetAllAgreementsByClient(e.value);
            GetReportAgreementStatus(e.value);
            getAgreementDetailReportTransaction(e.value);
            GetDonutAllAgreementsByClient(e.value);
            getAgreementPayment(e.value);

            getAgreementsCompany(e.value);
        }
        else {
            setInformationCompany({});
            setListAgreement([]);
        }
    }  

    const getInformationCompany = async (id_company) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `Company/GetInformationCompany/${id_company}`);

        if (respuesta.code === 200) {
            let dataCompany = {
                id_company: respuesta.data.id_company,
                code: respuesta.data.code,
                business_name: respuesta.data.business_name,
                rfc: respuesta.data.rfc,
                address: respuesta.data.address,
                cp: respuesta.data.cp,
                colony: respuesta.data.colony,
                phone: respuesta.data.phone,
                contact_email: respuesta.data.contact_email,
                id_company_turn: respuesta.data.id_company_turn,
                name_company_turn: respuesta.data.name_company_turn,
                agreement_global: respuesta.data.agreement_global
            }

            setInformationCompany(dataCompany);

        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getInformationCompany);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cobranza por cliente", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Cobranza por cliente", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     createSweet("error", "error", "Hubo un error!");
        // }
    }

    const getAgreementPayment = async (id_company) => {

        let requestOptions = {
            method: 'GET'
        };
        const respuesta = await sendRequest(requestOptions, `AgreementPayment/ListAgreementByClient/${id_company}`);

        if (respuesta.code === 200) {

            let paymentAgreement = []

         

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach((element,index) => {

                    element.listPayments.forEach((value,i) => {
                    
                    paymentAgreement.push({
                        id_agreement:element.id_agreement,
                        code_agreement: element.code_agreement,
                        id_user: value.id_user,
                        amount: value.amount,
                        name_user: value.name_user,
                        date: value.date,
                        time: value.time,
                        periodo: value.periodo,
                       
                    })

                 });
                    
                });

            }
            setTransaction(paymentAgreement);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getAgreementPayment);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cobranza por cliente", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Cobranza por cliente", respuesta.data.msg);
            setLoading(false)
        }
    }

  


    const GetAllAgreementsByClient = async (id_company) => {

        let requestOptions = {
            method: "GET"
        };

        const respuesta = await sendRequest(requestOptions, `Agreement/GetAllAgreementsByClient/${id_company}`);

        if (respuesta.code === 200) {

            setOption({
                chart: {
                    width: 380,
                    type: 'pie',
                },
                legend: {
                    position: 'bottom'
                },
                labels: respuesta.data.labels,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                    }
                }],
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "Adeudo:$ " + val
                        }
                    },

                    x: {
                        formatter: function (vals) {
                            return "Nombre convenio " + vals
                        }
                    }
                },
            })

            setSerie2(respuesta.data.series)
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, GetAllAgreementsByClient);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cobranza por cliente", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Cobranza por cliente", respuesta.data.msg);
            setLoading(false)
        }
      
    }

    const GetDonutAllAgreementsByClient = async (id_company) => {

        let requestOptions = {
            method: "GET"
        };

        const respuesta = await sendRequest(requestOptions, `Agreement/GetAllAgreementsByClient/${id_company}`);

        if (respuesta.code === 200) {

            //console.log(respuesta.data);

            setOption3({
                chart: {
                    width: 380,
                    type: 'donut',
                    dropShadow: {
                        enabled: true,
                        color: '#111',
                        top: -1,
                        left: 3,
                        blur: 3,
                        opacity: 0.2
                    }
                },
                legend: {
                    show: true,
                },

                stroke: {
                    width: 0,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    showAlways: true,
                                    show: true,
                                    label: 'Total',
                                    formatter: function (w) {
                                        return w.globals.seriesTotals.reduce((a, b) => {
                                            return "$ " + number_format((a + b), 2)
                                        }, 0)
                                    }
                                }
                            }
                        },
                    }
                },
                labels: respuesta.data.labels,
                dataLabels: {
                    formatter: function (val) {
                        return val + "%"
                    },
                    dropShadow: {
                        blur: 3,
                        opacity: 0.8,
                    },
                    position: 'top'
                },
                fill: {
                    type: 'gradient'


                    // opacity: 1,
                    // pattern: {
                    //     enabled: true,
                    // },
                },
                dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 3,
                    opacity: 0.5
                },

                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "Adeudo:$ " + number_format((val), 2)
                        }
                    },
                },
                states: {
                    hover: {
                        filter: 'none'
                    }
                },
                theme: {
                    palette: 'palette2'
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            })

            setSerie3(respuesta.data.series)
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, GetDonutAllAgreementsByClient);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cobranza por cliente", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Cobranza por cliente", respuesta.data.msg);
            setLoading(false)
        }
       
    }

    const downdloadpdf = async () => {
        

        let data = {
            id_company: formAgreement.id_company.value,
            id_agreement: idAgreementCompany,
            id_agreement_period: idPeriodCompany,
            send_email: false
        }

        let raw = JSON.stringify(data);

        let token = localStorage.getItem("token");

        await fetch("/api/Reports/DetailStaments", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })

            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Estado_De_Cuenta.pdf")
                document.body.appendChild(link)
                    ;
                link.style.display = 'none';
                link.click();

            })
    }

    const printPdf = async () => {

        let data = {
            id_company: formAgreement.id_company.value,
            id_agreement: idAgreementCompany,
            id_agreement_period: idPeriodCompany,
            send_email: false
        }

        let raw = JSON.stringify(data);


        let token = localStorage.getItem("token");

        await fetch("/api/Reports/DetailStaments", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })

            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var iframe = document.createElement('iframe');
                iframe.className = 'pdfIframe'
                document.body.appendChild(iframe);
                iframe.style.display = 'none';
                iframe.onload = function () {
                    setTimeout(function () {
                        iframe.focus();
                        iframe.contentWindow.print();
                        URL.revokeObjectURL(url)
                    }, 1);
                };
                iframe.src = url;
            });
        setPdf(false)
    }

    const sentPdf = async () => {


        let data = {
            id_company: formAgreement.id_company.value,
            id_agreement: idAgreementCompany,
            id_agreement_period: idPeriodCompany,
            send_email: true
        }

        let raw = JSON.stringify(data);

        let token = localStorage.getItem("token");

        await fetch("/api/Reports/DetailStaments", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })

            .then(response => {
                createSweet("success", "success", "Correo enviado correctamente");
            })
    }

    const getAgreementDetailReportTransaction = async (id_company) => {

        let requestOptions = {
            method: 'GET'
        };

        

        const respuesta = await sendRequest(requestOptions, `Agreement/GetAgreementDetailReportTransaction/${id_company}`);

        if (respuesta.code === 200) {

            let dataList = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value,index) => {

                    let posicion = index + 1;
                    let visibleTable = false;
        
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    dataList.push({
                        idC: value.contract_number,
                        contract_number: value.contract_number,
                        from: value.from,
                        to: value.to,
                        status: value.status,
                        amount: '$' + number_format(value.amount, 2),
                        total: '$' + number_format(value.total, 2),
                        visibleTable:visibleTable
                    });
                });
                setListAgreement(dataList);
                setDataAgrement(dataList);
            }
            else {
                setListAgreement([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getAgreementDetailReportTransaction);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cobranza por cliente", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Cobranza por cliente", respuesta.data.msg);
            setLoading(false)
        }
    }
//lab:ddd,
            const numberItems = 10;
            const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(listAgreement.length, numberItems);

            useEffect(() => {
                let startItems = 0;
                let endItems = numberItems;

                let aux = numberItems * currentPage;

                endItems = aux;

                startItems = endItems - numberItems;
                let listAgreementt = [];
                listAgreement.forEach((data, key) => {

                    let posicion = key + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listAgreementt.push({
                        ...data,
                    
                        visibleTable: visibleTable
                    });
                });

                setListAgreement(listAgreementt);

            }, [totalPageCount, currentPage])

            const [dataAgrement, setDataAgrement] = useState([])


            
            const handleSearchAgrement = (e) => {

                let busqueda = e.target.value;
                let newMethod = [];

                dataAgrement.forEach(element => {  

                    let cadena = element.contract_number.toUpperCase();
                    if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                        element.visibleTable = true;
                        newMethod.push(element);
                    }
                });
                setListAgreement(newMethod);
            }



    const GetReportAgreementStatus = async (id_company) => {

        let requestOptions = {
            method: "GET"
        };

        const respuesta = await sendRequest(requestOptions, `Agreement/GetReportAgreementStatus/${id_company}`);

        if (respuesta.code === 200) {


            setSeries(respuesta.data)
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, GetReportAgreementStatus);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cobranza por cliente", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Cobranza por cliente", respuesta.data.msg);
            setLoading(false)
        }
        // else {

        //     createSweet("error", "error", "Hubo un error!", "Error de  conexion");
        //     validarSesion(history, respuesta.code, GetReportAgreementStatus);
        // }
    }

    const handleCreate = (saldoTotal, agrement, id_agreement, balancePaid, total_balance, payment_method, id_financing_method) => {


        setModalTitle("Pagos Realizados");
        toggle();
        setSaldoTotal(saldoTotal);
        setAgremment(agrement);
        setBalancePaid(balancePaid);
        setTotal(total_balance);
        setPaymentMethod(payment_method);
        setPricepaymet(id_financing_method);

        setMethod("create")

        handleUpdateValuesAgreements({
            ...formAgreement,
            id_agreement: id_agreement,
            amount: 0
        });
    }

    const saveCreate = async (e) => {

        e.preventDefault();

        setLoading(true);

        let body = {

            id_agreement: formAgreement.id_agreement,
            id_company: formAgreement.id_company.value,
            amount: formAgreement.amount
        }

        let raw = JSON.stringify(body);

        let requestOptions = {
            method: 'POST',
            body: raw
        };

        const respuesta = await sendRequest(requestOptions, "AgreementPayment/Create");

        if (respuesta.code === 200) {


            createSweet("create", "success", "Se realizo de manera exitosa el abono");
            setLoading(false);

            handleUpdateValuesAgreements({
                ...formAgreement,
                id_agreement: 0,
                amount: 0
            });

            GetReportAgreementStatus(formAgreement.id_company.value);
            toggle();
            GetAllAgreementsByClient(formAgreement.id_company.value)
            getAgreementPayment(formAgreement.id_company.value)

        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cobranza por cliente", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Cobranza por cliente", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     validarSesion(history, respuesta.code, saveCreate);
        //     setLoading(false);
        // }
    }

    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }

    const getAgreementsCompany = async (id_company) => {
        let requestOptions = {
            method: 'GET'
        };
        console.log('estoy acá yo de nuevo')
        const respuesta = await sendRequest(requestOptions, `Agreement/ListAgreementByCompany/${id_company}`);
        console.log('respuesta')
        if (respuesta.code === 200) {
            let tmpArray = [];

            respuesta.data.forEach( obj => {
                tmpArray.push({
                    id_agreement: obj.id_agreement,
                    code: obj.code,
                    status: obj.status
                });
            });

            setAgreementsCompany(tmpArray);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getAgreementsCompany);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cobranza por cliente", "Ocurrio un problema en el servidor");
            setAgreementsCompany([]);
        }
        else {
            createSweet("error", "warning", "Cobranza por cliente", respuesta.data.msg);
            setAgreementsCompany([]);
        }
    }

    const handleChangeAgreement = (e) => {
        setIdAgreementCompany(e.target.value);

        getPeriodsCompany(e.target.value);
    }

    const getPeriodsCompany = async (id_agreement) => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `Agreement/ListPeriodsByAgreement/${id_agreement}`);

        if (respuesta.code === 200) {
            let tmpArray = [];

            respuesta.data.forEach( obj => {
                tmpArray.push({
                    id_agreement_period: obj.id_agreement_period,
                    from_to: obj.from_to
                });
            });

            setPeriodsAgreement(tmpArray);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getPeriodsCompany);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Cobranza por cliente", "Ocurrio un problema en el servidor");
            setPeriodsAgreement([]);
        }
        else {
            createSweet("error", "warning", "Cobranza por cliente", respuesta.data.msg);
            setPeriodsAgreement([]);
        }
    }

    const handleChangePeriod = (e) => {
        setIdPeriodCompany(e.target.value);
    }


    return {
        //SELECT INFORMATION COMPANY
        informationCompany, handleSelectCompany, id_company, handleSelectValuesAgreements, handleInputChangeAgreement, companies,
        //COMPONENT MODAL PRICE
        handleCreate, modalTitle, loading, modal, toggle, saldoTotal, agreement, balancePaid, total, paymentMethod, pricepaymet,
        //PDF
        downdloadpdf, pdf, printPdf, sentPdf,
        //GRAPH PIE
        serie2, option,
        //GRAPH AGREEMENT COSTUMER
        series,
        //AMOUNT AGREEMENT
        id_agreement, amount, saveCreate, handleUpdateValuesAgreements, loading, method,
        number_format,
        //listAgreement 
        listAgreement,
        //GRAPH DONUT
        option3, serie3,
        //TRANSACTION 
        transaction, cardName, cardPeriodos, cardTotal,
        //VALUES FOR PRINT, DOWNLOAD, SEND PDF
        idAgreementCompany, idPeriodCompany,
        handleChangeAgreement, handleChangePeriod,
        agreementsCompany, periodsAgreement,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchAgrement,


    }
}
