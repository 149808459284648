import React from 'react'
import { Button, Card, Col, CardBody, Input, Row, Media } from 'reactstrap'
import { TableStriped } from '../../../components/tables/TableStriped'
import { ModalUser } from '../../../components/users/ModalUser'
import { ModalCard } from '../../../components/users/ModalCard'
import { useUser } from '../../../hooks/usersRols/useUser'
import { ModalViewSection } from './ModalViewSection'
import { Search, Edit, Globe, BookOpen, FileText, Youtube, MessageCircle, Mail, RotateCcw, DollarSign, Check, Link, Codepen, HelpCircle, Aperture, Settings, MessageSquare } from 'react-feather';
import { ViewSections } from './ViewSections'

export const ComponentUser = ({ activeTab }) => {
    const {
        modalTitle, modalUser, methodUser, toggleUser, typeCard, checkbox, check, 
        handleCheckboxChange, section, changeTree, treeviewRef, tree, onRenderItem,
        saveCardCode, handleListSectionsCard, card_code, checkSection, handleCheckChange,
        listDepartament,
        //USERS MODEL
        listUsers, listRol, loading, name_user, listRoles, handleInputChange, 
        handleSelectValues, validationsUser, handleInputChangeT, id_departament,
        departament, 
        //USERS METHODS
        handleUpdate, saveUpdatePatient, totalPageCount, currentPage, handleListCard,
        nextPage, previousPage, goToPage, handleSearchUser, handleListSection,
        modalTitleSection, modalSection, toggleSection, typeModal, selected, 
        onChange, data, imagen, saveUpdateSections, listSection, listSect,
        modalTitleSection2, modalSection2, typeModalSection, handleListSections,
        toggleSection2, dataSections, sectionSelected, handleCard, handleSectionUser,
        //card
        modalCard, toggleCard, modalTitleCard, processes, formUser, id_departaments,
        listDepa,
    } = useUser();

    console.log("lista",listUsers);


    return (
        <>
            <Card>
                <CardBody className='p-1'>
                    <Row className='m-t-2'>
                        <div className='offset-8 col-4 pt-1 pb-2'>
                            <Input placeholder='Búsqueda...' className='form-control' size="sm" onChange={handleSearchUser} />
                        </div>
                    </Row>
                    <Row className='m-t-2'>
                        <Col sm="12" md="12" lg="12">
                            <div className='table-responsive'>
                                <TableStriped
                                    notMaped={["id_helen_users", "id_external", "name_user", "paternal_surname", "maternal_surname", "job", "card_code", "areas", "array_roles", "visibleTable", "ids_roles", "ids_sections", "sections"]}
                                    methodsActions={true}
                                    methodsModal={false}
                                    cabeceras={["Nombre", "Usuario", "Roles"]}
                                    items={listUsers}
                                    {...{ handleUpdate }} 
                                    
                                    methodsAbsolutes={
                                        [
                                            {
                                                type: "linkId",
                                                method: handleListSection,
                                                icon: "icofont icofont-prescription",
                                                backgroundColor: "#016392",
                                                color: "#fff",
                                                tooltip: "Secciones/Áreas"
                                            },
                                            {
                                                type: "linkId",
                                                method: handleListCard,
                                                icon: "icofont icofont-credit-card",
                                                backgroundColor: "#b32b5d",
                                                name: "handleListCard",
                                                color: "#fff",
                                                tooltip: "Tarjeta"
                                            }
                                        ]  
                                    }

                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    totalPageCount={totalPageCount}
                                    currentPage={currentPage}
                                    goToPage={goToPage}
                                    // methodActiveModal={[
                                    //     {
                                    //         type: "ModIdM",
                                    //         method: handleListSections,
                                    //         icon: "icofont icofont-laboratory f-12",
                                    //         name: "handleListSections",
                                    //         color: 'secondary',
                                    //         tooltip: "Secciones",
                                    //     },
                                    // ]}
                                />
                            </div>
                            <br /> <br />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <ModalCard
                {
                    ...{
                        modalCard, modalTitleCard, toggleCard, loading, handleListCard,
                        typeCard, checkbox, check, handleCheckboxChange, handleInputChangeT, id_departament,
                        departament, handleSectionUser, section, changeTree, treeviewRef, tree,
                        onRenderItem, processes, saveCardCode, handleListSectionsCard,
                        card_code, handleInputChange, checkSection, handleCheckChange, listDepartament,
                        handleSelectValues, formUser, id_departaments, listDepa,
                    }
                }
            />
            <ModalUser
                {
                ...{
                    modal: modalUser, modalTitle, toggle: toggleUser, method: methodUser, loading,
                    listRol, name: name_user, listRoles,
                    handleInputChange, handleSelectValues,
                    saveUpdatePatient, validaciones: validationsUser
                }
                }
            />
            <ModalViewSection
                {
                ...{
                    modalTitleSection, modalSection, loading, toggleSection, typeModal, selected, onChange, data, saveUpdateSections,
                    listSection, listSect, handleSelectValues, sectionSelected
                }
                }
            />
            <ViewSections
                {
                ...{ modalTitleSection2, modalSection2, typeModalSection, handleListSections, toggleSection2, loading, dataSections, }
                }
            />
        </>
    )
}
