import React, { Fragment, useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, FormText, Input, Label, Nav, NavItem, NavLink, Row } from 'reactstrap'
import { useInvoiceService } from '../../hooks/invoice/useInvoiceService'
import { TabAdditionalInformation } from './TabAdditionalInformation';
import { ModalCompany } from '../../components/companies/ModalCompany';
import { InputTypeClient } from './InputTypeClient';
import { PlaceHolderOption } from '../../constant';
import { ModalPatient } from '../../components/patients/ModalPatient';
import { TabConceptsInvoice } from './TabConceptsInvoice';
import { ModalPreviewInvoice } from '../../components/Invoice/ModalPreviewInvoice';
import Select from 'react-select';
import { ModalAddExtraPatient } from '../../components/Invoice/ModalAddExtraPatient';
import { TabRelatedInvoice } from './TabRelatedInvoice';
import { ModalAddEgress } from '../../components/Invoice/ModalAddEgress';
import { TabConceptsEgrees } from './TabConceptsEgrees';
import './StyleInvoiceClient.css';


export const IndexInvoiceService = () => {

    const {
        //GLOBAL VARIABLES
        loading, loadingPreview, loadingFilterWorkOrders,

        //MODALS AND MODALS METHODS
        modalBusinessName, modalPatient, modalPreviewInvoice, modalExtraPatients, modalListEgress, modalTitle, method,
        toggleModalBusinessName, toggleModalPatient, toggleModalPreviewInvoice, toggleModalExtraPatients, toggleModalListEgress,

        //INVOICE -> MODELS
        formInvoice,
        id_patient, id_company, id_company_business_name, id_commercial_line, id_pay_form, id_periodicity,
        id_method_pay, id_coin, id_use_cfdi, id_month, id_type_releationship,
        type_document, expedition_place, tax_iva, payment_conditions, comments_invoice, is_general_public, information_general_public,
        viewSearchPatient, viewSearchClient, viewFilterNims,
        subtotalInvoice, totalDiscountsInvoice, totalTaxInvoice, totalRetentions, totalInvoice, 
        iconCoin, disabledSaveButton,
        filterWorkOrder, filterInvoices,

        filterTypeClient,
        listPayForms, listPayMethods, listCoins, listCommercialLines, listTaxRegime, listUseCfdi,
        listExamsInvoice, listNims, listPeriodicities, listCfdiMonths, listTaxes,
        listInvoices, listFilterPayMethods, listTypeRelationship,

        typeMethodDiscount, amountDiscount, isGlobalDiscount,

        //BUSINESS NAME -> VALIDATIONS
        validationsInvoice,

        //INVOICE -> METHODS
        handleInputChange, handleUpdateValues, handleSelectValues,
        handleChangeTypeInvoice, handleChangeTypeClient,
        handleChangeTypeMethods, handleChangeNim,
        setViewSearchPatient, setViewSearchClient,
        handleChangePeriodicity,
        handleChangeMonthGenPub,
        handlePreviewInvoice, saveInvoiceHelen,
        handleChangeInvoice, handleChangePaymentAmount,
        handleChangeCommercialLines, handleSelectInvoice,
        handleChangeTypeDiscount, handleChangeAmountDiscount,
        handleChangeGlobalDiscount, handleChangeConcept,

        //BUSINESS NAME -> MODELS
        id_tax_regime_business_name, business_name, rfc_business_name, cp_business_name,
        email_business_name, tax_regime_business_name, type_person_business_name, informationBusinessName, setInformationBusinessName,
        //BUSINESS NAME -> METHODS
        handleInputChangeBusinessName, handleUpdateBusinessName, saveUpdateBusinessName, validSelectBusinessName,

        //BUSINESS NAME -> VALIDATIONS
        validationsBusinessName,

        //PATIENT -> MODELS
        searchPatientState,
        id_tax_regime_patient, type_person_patient, cp_patient, tax_regime_patient, rfc_patient, email_patient, business_name_patient,
        informationPatient, setInformationPatient, searchPatientExtra, searchPatientValue, listTempExtraPatients,
        loadingExtraPatients,

        //PATIENT -> METHODS
        handleInputChangePatient, handleUpdatePatient, saveUpdatePatient, validSelectPatient,
        handleChangePayMethodPatient, handleSearchExtraP, handleChangeExtraPatient, handleAddExtraPatients,
        handleChangeTypePatient,

        //PATIENT -> VALIDATIONS
        validationsPatient,

        //GENERAL PUBLIC -> MODELS
        handleInputChangeGenPub, date_periodicity, week_from_periocity, week_to_periocity, idFilterPayMethod,
        validDateFrom, validDateTo,

        //GENERAL PUBLIC -> METHODS
        handleChangePayMethodGeneralPublic, handleFilterSearchNims,

        //#region DASHBOARD INVOICE
        id_work_order_r, id_patient_r, handleDashboard,_id_company_r,handleSelectValuesGenPub,
        //#endregion DASHBOARD INVOICE

        number_format,id_branchI,branch,

        //FILTER RANGE COMPANY
        setId_company_filter,handleCloseFilterCompany,view_filter_company,handleViewCompany,dateFrom,dateTo,handleInputChangeFech,removeInvoice,

        //FILTER INVOICE COMPANY    
        type_invoice_company,handleChangeInvoiceCompany,companyInvoiceGlobal,

        //GROUP BY EXAM AND PROFILE
        dicountGlobal,totalGlobal,description,examInvoice,priceInvoice,
        request_id_invoice,

         //type description
         handleChangeDescription,is_general_public_type_description,typeGlobal,

         date_payment,subtotalC,totalTaxC,totalC,

         subtotalN,totalTaxN,totalN,
         codeGlobal,handleChangeProductCode

    } = useInvoiceService();

    //*LOCAL STORAGE
    let validUrl = localStorage.getItem('url_helen');


    const AlertItem = props => {
        const { item } = props;
        const [Open, setOpen] = useState(true);

        return (
            <Alert className="alert-dismissible"
                color={item.alertcolor}
                isOpen={Open}
                target={"Alert-" + item.id}
            >
                {item.icon}
                <label>{item.alerttxt}</label>
            </Alert>
        );
    }

    const {
        _id_commercial_line_valid,
    } = validationsInvoice;


    let _validCommercialLine = (validUrl === "https://centroquimico.helen-sw.com") ?  true : false

    return (
        <Fragment>
            <Container fluid={true} className='p-l-0 p-r-0' >
                <Row className="mt-2 mb-3">
                    <Col sm="3" md="3" lg="3" className="default-according style-1 faq-accordion job-accordion position-relative">
                        <Row className="position-sticky">
                            <Col sm="12" md="12" className='pr-0 file-spacing'>
                                <div className='file-sidebar'>
                                    <Card>
                                        <CardBody>
                                            <ul>
                                                <li className='text-xs-center'>
                                                    <div className='btn bangeColor text-xs-center' style={{cursor:'none'}}>{request_id_invoice === undefined ? "Documento a generar" : "Documento generado"}    </div>
                                                </li>
                                                <li><hr /></li>
                                                <li className={request_id_invoice === undefined ? "" : "disabled"}   > 
                                                    <div  className={`btn ${type_document === 'I' ? 'btn-primary-gradien' : 'btn-outline-info-2x'}`} onClick={() => handleChangeTypeInvoice("I")}><i className="icofont icofont-list"></i> &nbsp;&nbsp; {request_id_invoice === undefined ? "Factura" : "Prefactura"} </div>
                                                </li>
                                                <li className={id_work_order_r === undefined ? "" : "d-none"} >
                                                    <div className={request_id_invoice === undefined ? "" : "d-none"}>
                                                    <div className={`btn ${type_document === 'E' ? 'btn-primary-gradien' : 'btn-outline-info-2x'}`} onClick={() => handleChangeTypeInvoice("E")} ><i className="icofont icofont-list"></i> &nbsp;&nbsp; Nota de credito</div>
                                                    </div>
                                                </li>
                                                <li className={id_work_order_r === undefined ? "" : "d-none"}>
                                                    <div className={request_id_invoice === undefined ? "" : "d-none"}>
                                                    <div className={`btn ${type_document === 'P' ? 'btn-primary-gradien' : 'btn-outline-info-2x'}`} onClick={() => handleChangeTypeInvoice("P")} ><i className="icofont icofont-list"></i> &nbsp;&nbsp; Complemento de Pago</div>

                                                    </div>
                                                </li>
                                            </ul>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                        <Row className="position-sticky">
                            <Col sm="12" md="12" className='pr-0 file-spacing'>
                                <div className='file-sidebar'>
                                    <Card>
                                        <CardBody>
                                            <ul>
                                                <li className='text-center'>
                                                    <div className='btn bangeColor text-center' style={{cursor:'none'}}>Acciones rapidas</div>
                                                </li>
                                                <li><hr /></li>
                                                <li>
                                                    <Button className={`btn btn-primary-gradien ${disabledSaveButton ? ' disabled' : ''}`} onClick={handlePreviewInvoice} disabled={disabledSaveButton}><i className="icofont icofont-optic"></i> &nbsp;&nbsp; {
                                                                (request_id_invoice === undefined ? "Previsualizar" : "Previsualizar Prefactura")
                                                            }</Button>
                                                </li>
                                                <li>
                                                    <Button className='btn btn-success-gradien' onClick={() => saveInvoiceHelen(false)} disabled={disabledSaveButton}>
                                                        <i className="icofont icofont-file-pdf"></i>
                                                        &nbsp;&nbsp; {type_document === "P" ? "Generar complemento de pago" : (type_document === "I" ? "Generar factura" : "Generar Nota de crédito")}
                                                    </Button>
                                                </li>
                                                <li className={(id_work_order_r === undefined ? "" : "d-none") } >
                                                    <div className={(filterTypeClient === "2" ? "" : "d-none" )} >
                                                        <div className={type_document === "I" ? '' : 'd-none' }>
                                                        <Button className='btn btn-warning-gradien' onClick={() => saveInvoiceHelen(true)} disabled={disabledSaveButton}>
                                                            <i className="icofont icofont-file-pdf"></i>
                                                            &nbsp;&nbsp; {
                                                                (request_id_invoice === undefined ? "Generar Prefactura" : "Guardar edición de Prefactura")
                                                            }
                                                        </Button>

                                                    </div>
                                                    

                                                    </div>
                                                  
                                                </li>
                                            </ul>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                        <Row className={filterTypeClient === '2' ? "position-sticky" : "d-none"}  >
                            <Col sm="12" md="12"  className={type_document === "I" ? 'pr-0 file-spacing' : 'd-none'}>
                                <div className='file-sidebar'>
                                    <Card>
                                    <CardBody>
                                        <ul>
                                            <li className='text-center'>
                                                    <div className='btn bangeColor text-center' style={{cursor:'none'}}>{request_id_invoice === undefined ? "Tipo de factura" : "Tipo de prefactura"} </div>
                                                </li>
                                                <li><hr /></li>
                                            <li>
                                                <div className={`btn ${type_invoice_company === '1' ? 'btn-dark-gradien' : 'btn-outline-info-2x'}`}  onClick={() => handleChangeInvoiceCompany("1")}><i className="icofont icofont-list"></i> &nbsp;&nbsp; Detallado</div>
                                            </li>
                                            <li className={id_work_order_r === undefined ? "position-sticky" : "d-none"}>
                                                <div className={`btn ${type_invoice_company === '2' ? 'btn-dark-gradien' : 'btn-outline-info-2x'}`}  onClick={() => handleChangeInvoiceCompany("2")}><i className="icofont icofont-list"></i> &nbsp;&nbsp; Concentrado global</div>
                                            </li>
                                            <li className={id_work_order_r === undefined ? "position-sticky" : "d-none"}>
                                                <div className={`btn ${type_invoice_company === '3' ? 'btn-dark-gradien' : 'btn-outline-info-2x'}`}  onClick={() => handleChangeInvoiceCompany("3")}><i className="icofont icofont-list"></i> &nbsp;&nbsp; Concentrado por estudios </div>
                                            </li>
                                        </ul>
                                    </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                        <Row className={id_work_order_r === undefined ? "d-none" : "position-sticky"}  >
                            <Col sm="12" md="12" className='pr-0 file-spacing'>
                                <div className='file-sidebar'>
                                    <Card>
                                        <CardBody>
                                            <ul>
                                                <div className={`btn btn-outline-info-2x`} onClick={() => handleDashboard()}><i className="icofont icofont-list"></i> &nbsp;&nbsp; Regresar</div>
                                            </ul>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="9" md="9" lg="9">
                        <Row>
                            <Col md="12" className='project-list'>
                                <Card>
                                    <Row>
                                        <Col  sm={request_id_invoice === undefined ? 2 :4} >
                                        </Col>
                                        <Col sm={request_id_invoice === undefined ? 10 :10}>
                                            <Nav tabs className='border-tab'>
                                                <NavItem  className={request_id_invoice === undefined ? "" : (filterTypeClient === "1" ? "active " : "d-none" ) } >
                                                    <NavLink disabled={type_document === "P" ? true : false} className={filterTypeClient === "1" ? "active " : ""} onClick={() => handleChangeTypeClient("1")}><i className="icofont icofont-user-alt-7 "></i>  Paciente particular </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={filterTypeClient === "2" ? "active" : ""} onClick={() => handleChangeTypeClient("2")}><i className="fa fa-building "></i> {
                                                                (request_id_invoice === undefined ? "Empresa" : "Edición de la prefactura de empresa.")
                                                            }      </NavLink>
                                                </NavItem>
                                                <NavItem className={id_work_order_r === undefined ? "" : "d-none"} >
                                                    <div className={request_id_invoice === undefined ? "" : (filterTypeClient === "" ? "active " : "d-none" ) }>
                                                    <NavLink disabled={type_document === "P" ? true : false} className={filterTypeClient === "3" ? "active" : ""} onClick={() => handleChangeTypeClient("3")}><i className="fa fa-group "></i>  Público en general </NavLink>


                                                    </div>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="12">
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        {
                                            ((validationsPatient.rfc_patient_valid || validationsPatient.id_tax_regime_patient_valid || validationsPatient.cp_patient_valid) ||
                                                (validationsBusinessName.business_name_valid || validationsBusinessName.cp_business_name_valid || validationsBusinessName.id_tax_regime_business_name_valid)) &&
                                            <AlertItem
                                                item={
                                                    {
                                                        alerttxt: "Para poder generar el documento, es necesario actualizar la razón social, el código postal del domicilio fiscal, conforme a la constancia de situación fiscal.",
                                                        alertcolor: 'danger inverse',
                                                        btnclose: 'default',
                                                        icon: <i className="icon-alert"></i>
                                                    }
                                                }
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6" md="6" lg="6">
                                        <Card className='cardBox' >
                                            <CardHeader className='p-3'>
                                                <h6 className='labelConcept'>Datos del receptor</h6>
                                            </CardHeader>
                                            <CardBody className='p-2'>
                                                {
                                                    <InputTypeClient
                                                        typeClient={filterTypeClient}
                                                        formInvoice={formInvoice}
                                                        handleInputChange={handleInputChange}
                                                        handleUpdateValues={handleUpdateValues}
                                                        {
                                                        ...{
                                                            id_patient, id_company, id_company_business_name,

                                                            is_general_public, information_general_public,

                                                            informationBusinessName, setInformationBusinessName, handleUpdateBusinessName, validSelectBusinessName,

                                                            searchPatientState, informationPatient, setInformationPatient, handleUpdatePatient, validSelectPatient,

                                                            viewSearchPatient, setViewSearchPatient,

                                                            viewSearchClient, setViewSearchClient,

                                                            validationsInvoice, handleChangePayMethodGeneralPublic, handleChangePayMethodPatient,

                                                            id_work_order_r, id_patient_r,_id_company_r,setId_company_filter,

                                                            handleCloseFilterCompany,view_filter_company,id_work_order_r,
                                                            request_id_invoice,                                                            
                                                        }
                                                        }
                                                    />
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm="6" md="6" lg="6">
                                        <Card className='cardBox' >
                                            <CardHeader className='p-3'>
                                                <h6 className='labelConcept'>Datos del emisor</h6>
                                            </CardHeader>
                                            <CardBody className='p-2'>
                                                <Row>
                                                    <Col sm="12" md="12" lg="12">
                                                        <FormGroup>
                                                            <Label className='col-form-label'>Razón social  <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                            <Select
                                                                className="input-air-primary input-xs"
                                                                classNamePrefix="select"
                                                                name="id_commercial_line"
                                                                value={id_commercial_line}
                                                                options={ _validCommercialLine === true ? [listCommercialLines[0]]  : listCommercialLines } 
                                                                placeholder={PlaceHolderOption}
                                                                isClearable={true}
                                                                onChange={(e) => handleChangeCommercialLines(e)}
                                                            />
                                                            <FormText color='danger' hidden={!_id_commercial_line_valid}>Selecciona una razón social</FormText>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <div className={id_commercial_line === 0 ? 'd-none' : ''}>
                                                    <Row>
                                                        <Col sm="12" md="12" lg="12">
                                                            <div className='media'>
                                                                <div className='media-body'>
                                                                    <small>Régimen fiscal</small>
                                                                    <p ><i className='fa fa-circle text-info'></i> {request_id_invoice === undefined? id_commercial_line.name_tax_regime : formInvoice.name_tax_regime_issuing}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-2'>
                                                        <Col sm="12" md="12" lg="12">
                                                            <div className='media'>
                                                                <div className='media-body'>
                                                                    <small>Código postal</small>
                                                                    <p><i className='fa fa-circle text-info'></i> {request_id_invoice === undefined ?  id_commercial_line.cp :  formInvoice.expedition_place }</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <Card>
                                            <CardHeader className='p-3'>
                                                <h6 className='labelConcept'>Datos del documento</h6>
                                            </CardHeader>
                                            <CardBody className='p-2'>
                                                <TabAdditionalInformation
                                                    listPayForms={listPayForms}
                                                    listPayMethods={listPayMethods}
                                                    listCoins={listCoins}
                                                    listPeriodicities={listPeriodicities}
                                                    listCfdiMonths={listCfdiMonths}
                                                    listTaxes={listTaxes}
                                                    listTypeRelationship={listTypeRelationship}
                                                    handleInputChange={handleInputChange}
                                                    handleSelectValues={handleSelectValues}
                                                    {
                                                    ...{
                                                        type_document, listUseCfdi, filterTypeClient,

                                                        handleChangePeriodicity,

                                                        expedition_place, id_pay_form, id_method_pay, id_coin, id_use_cfdi, id_periodicity, id_month, tax_iva, payment_conditions,
                                                        id_type_releationship,

                                                        handleChangeTypeMethods, validationsInvoice,

                                                        handleChangePayMethodGeneralPublic,id_work_order_r

                                                    }
                                                    }
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <Card>
                                            <CardHeader className='p-3'>
                                                <h6 className='labelConcept'>Conceptos</h6>
                                            </CardHeader>
                                            <CardBody className='p-2'>
                                                {
                                                    type_document === "I"
                                                        ? 
                                                        <TabConceptsInvoice
                                                            {
                                                            ...{
                                                                loadingFilterWorkOrders,
                                                                
                                                                listExamsInvoice, listNims, listFilterPayMethods,
                                                                handleChangeNim,

                                                                subtotalInvoice, totalDiscountsInvoice, totalTaxInvoice, totalInvoice, 
                                                                totalRetentions, iconCoin,

                                                                validationsInvoice,

                                                                comments_invoice, handleInputChange,

                                                                is_general_public, id_periodicity, id_month, viewFilterNims,

                                                                handleInputChangeGenPub, date_periodicity, week_from_periocity, week_to_periocity, idFilterPayMethod, filterWorkOrder,

                                                                handleChangeMonthGenPub,

                                                                validDateFrom, validDateTo,

                                                                handleFilterSearchNims,

                                                                filterTypeClient, handleSearchExtraP,

                                                                id_branchI,branch,handleSelectValuesGenPub,

                                                                handleCloseFilterCompany,view_filter_company,handleViewCompany,
                                                                dateFrom,dateTo,handleInputChangeFech,removeInvoice,type_invoice_company,
                                                                dicountGlobal,totalGlobal,description,formInvoice,examInvoice,priceInvoice,
                                                                id_work_order_r, request_id_invoice,

                                                                handleChangeDescription,is_general_public_type_description,typeGlobal,codeGlobal,handleChangeProductCode

                                                            }
                                                            }
                                                        />
                                                        :
                                                            type_document === "E"
                                                            ?
                                                                <TabConceptsEgrees
                                                                    {
                                                                        ...{
                                                                            typeMethodDiscount, amountDiscount, isGlobalDiscount,
                                                                            handleChangeTypeDiscount, handleChangeAmountDiscount, handleChangeGlobalDiscount, handleChangeConcept,
                                                                            listInvoices,
                                                                            subtotalInvoice, totalDiscountsInvoice, totalTaxInvoice, totalInvoice,disabledSaveButton
                                                                            
                                                                        }
                                                                    }
                                                                />
                                                            :
                                                            <TabRelatedInvoice
                                                                {
                                                                ...{
                                                                    handleChangeInvoice, handleChangePaymentAmount,
                                                                    filterInvoices, iconCoin,
                                                                    listNims, listInvoices,date_payment,handleInputChange,disabledSaveButton
                                                                }
                                                                }
                                                            />
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <ModalCompany
                {
                ...{
                    modal: modalBusinessName, toggle: toggleModalBusinessName,
                    modalTitle, method,

                    id_tax_regime_business_name, business_name, rfc_business_name, cp_business_name,
                    email_business_name, tax_regime_business_name, type_person_business_name,

                    listTaxRegime,

                    handleInputChange: handleInputChangeBusinessName, saveUpdateBusinessName,

                    validationsBusinessName
                }
                }
            />
            <ModalPatient
                {
                ...{
                    modal: modalPatient, toggle: toggleModalPatient, modalTitle,
                    method, loading,

                    id_tax_regime: id_tax_regime_patient, type_person: type_person_patient,
                    cp: cp_patient, tax_regime_patient, rfc: rfc_patient, email: email_patient,
                    business_name: business_name_patient,

                    listTaxRegime,

                    handleInputChange: handleInputChangePatient, saveUpdate: saveUpdatePatient,

                    validationsPatient, handleChangeTypePatient,
                }
                }
            />
            <ModalPreviewInvoice
                {
                ...{
                    loadingPreview,
                    modalPreviewInvoice, toggleModalPreviewInvoice, formInvoice,
                    informationPatient, informationBusinessName, filterTypeClient,
                    listExamsInvoice, number_format,
                    subtotalInvoice, totalDiscountsInvoice, totalTaxInvoice, totalInvoice,
                    comments_invoice,
                    type_document,
                    //complemento
                    listInvoices,subtotalC,totalTaxC,totalC,
                    //nota de credito
                    id_method_pay,
                    subtotalN,totalTaxN,totalN
                }
                }
            />
            <ModalAddExtraPatient
                {
                ...{
                    searchPatientExtra, searchPatientValue,
                    modalExtraPatients, toggleModalExtraPatients, modalTitle, handleChangeExtraPatient,
                    listTempExtraPatients, handleAddExtraPatients, loadingExtraPatients
                }
                }
            />

            <ModalAddEgress
                {
                    ...{
                        modalTitle, modalListEgress,
                        listNims,
                        toggleModalListEgress,
                        handleSelectInvoice
                    }
                }
            />
        </Fragment>
    )
}       