import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, Input, Row, Tooltip } from 'reactstrap'
import Select from 'react-select';
import { sendRequest } from '../../../hooks/requests/useRequest';
import { validarSesion } from '../../../hooks/tokens/useToken';
import { useSweetAlert } from '../../../hooks/sweetAlerts/useSweetAlert';
import { useHistory } from 'react-router-dom';
import { InputAntibiogram } from './InputAntibiogram';

export const InputsMicro = ({
    idWorkOrderExam,
    idExam,
    bacter,
    changeDataMicro,
    removeMicroAislado,
    validateBacterium,

    useAntibiogram,
    micbacteriasSelected,
    microAntibiograms,
    addAntibiogram,
    changeDataAntibiogram,
}) => {

    const history = useHistory();

    const [morphologys, setMorphologys] = useState([]);
    const [bacterias, setBacterias] = useState([]);
    const [bacteriumsOptions, setBacteriumsOptions] = useState([]);
    const [identificationTests, setIdentificationTests] = useState([]);

    const [inputNumber, setInputNumber] = useState("");
    const [inputText, setInputText] = useState("");
    const [inputGram, setInputGram] = useState("");
    const [inputBacter, setInputBacter] = useState("");
    const [inputIdentificationTest, setInputIdentificationTest] = useState("");

    const [tooltipRelease, setTooltipRelease] = useState(false);


    const [createSweet] = useSweetAlert();

    const toggleTooltipRelease = () => setTooltipRelease(!tooltipRelease);


    useEffect(() => {
        // //console.log(bacter);
        // //console.log("pasa cada cuadno por aca?");
        getMorphologys().then(morfos => {
            getIdentificationTests().then(respuesta => {
                // alert("hola");
                // //console.log("entraaaaa", bacter);
                if (bacter.id_mic_identification_test !== undefined && bacter.id_mic_identification_test !== null && bacter.id_mic_identification_test !== "") {
                    let prueba_identificacion = respuesta.find(x => x.id_mic_identification_test === bacter.id_mic_identification_test);
                    setInputIdentificationTest({ "value": prueba_identificacion.id_mic_identification_test, "label": prueba_identificacion.name + "[" + prueba_identificacion.description + "]" })
                }
            });
            getBacteriums().then(respuesta => {
                if (bacter.cantidad_numero !== undefined && bacter.cantidad_numero !== "") {
                    setInputNumber(bacter.cantidad_numero);
                }

                if (bacter.cantidad_texto !== undefined && bacter.cantidad_texto !== "") {
                    setInputText({ "value": bacter.cantidad_texto, "label": bacter.cantidad_texto });
                }

                if (bacter.gram !== "" && bacter.id_mic_bacterium !== "") {
                    let bacteriums = respuesta.filter(x => {
                        if (x.gram === bacter.gram) {
                            return x;
                        }
                    });

                    let grupos = [];

                    morfos.forEach(mor => {
                        let bacters = bacteriums.filter(x => {
                            if (x.id_mic_morphology === mor.id_mic_morphology) {
                                x.value = x.id_mic_bacterium;
                                x.label = x.bacterium;
                                return x;
                            }
                        })

                        grupos.push({
                            label: mor.morphology,
                            options: bacters,
                        });
                    });
                    setInputGram({ "value": bacter.gram, "label": bacter.gram });
                    setBacteriumsOptions(grupos);
                    // changeDataMicro("gram", bacter.cantidad_texto, null, bacter.id_mic_bacterium);
                    // //console.log("LOGGOGOGO", bacter);
                    setInputBacter({ "label": bacter.name_bacterium === undefined ? bacter.label : bacter.name_bacterium, "value": bacter.id_mic_bacterium })
                    // changeDataMicro("id_bacterium", bacter.id_mic_bacterium, null, bacter.id_mic_bacterium, bacter.name_bacterium)
                }
            });
        });

    }, [])

    useEffect(() => {
        // //console.log(identificationTests, bacter);
        if (identificationTests.length > 0) {
            if (bacter.id_mic_identification_test !== undefined && bacter.id_mic_identification_test !== null && bacter.id_mic_identification_test !== "") {
                let puebasIdentificacion = identificationTests;
                let prueba_identificacion = puebasIdentificacion.find(x => x.id_mic_identification_test === bacter.id_mic_identification_test);
                setInputIdentificationTest({ "value": prueba_identificacion.id_mic_identification_test, "label": prueba_identificacion.name + "[" + prueba_identificacion.description + "]" })
            }
        }
    }, [bacter.id_mic_identification_test, identificationTests])

    const cambioPrueba = (e) => {
        changeDataMicro("id_mic_identification_test", e.value, bacter.id_temp, bacter.id_bacterium, e.label)
        setInputIdentificationTest({ "value": e.value, "label": e.label });
    }

    const getMorphologys = async () => {
        let requestOptions = {
            method: 'GET'
        }

        const respuesta = await sendRequest(requestOptions, "Morphology/List");

        if (respuesta.code === 200) {
            setMorphologys(respuesta.data);
            return respuesta.data;
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getMorphologys);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Alerta", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Alerta", respuesta.data.msg);
        }
    }

    const getBacteriums = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Bacterium/List");

        if (respuesta.code === 200) {
            setBacterias(respuesta.data);
            return respuesta.data;
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getBacteriums);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Bacteriumas", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Bacteriumas", respuesta.data.msg);
        }
    }

    const getIdentificationTests = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "IdentificationTest/List");

        if (respuesta.code === 200) {
            let pruebas = respuesta.data.map(x => {
                x.value = x.id_mic_identification_test;
                x.label = x.name + "[" + x.description + "]";
                return x;
            });
            setIdentificationTests(pruebas);
            return respuesta.data;
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getIdentificationTests);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Mecanismos de resistencia", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Mecanismos de resistencia", respuesta.data.msg);
        }
    }

    const handleValueGram = ({ label, value }) => {
        let bacteriums = bacterias.filter(x => {
            if (x.gram === value) {
                return x;
            }
        });

        let grupos = [];

        morphologys.forEach(mor => {
            let bacters = bacteriums.filter(x => {
                if (x.id_mic_morphology === mor.id_mic_morphology) {
                    x.value = x.id_mic_bacterium;
                    x.label = x.bacterium;
                    return x;
                }
            })

            grupos.push({
                label: mor.morphology,
                options: bacters,
            });
        });
        setInputGram({ label, value });
        setBacteriumsOptions(grupos);
        changeDataMicro("gram", value, bacter.id_temp, bacter.id_bacterium);
    }
    // (campo, valor, idTem, id_bacterium)
    const changeInput = (tipo, valor, bacter_temp, bacter_id) => {
        // //console.log(tipo, valor, bacter_temp, bacter_id, "---yehuiiii");
        if (tipo === "cantidad_texto") {
            setInputText(valor);
            setInputNumber("");
            changeDataMicro(tipo, valor.value, bacter_temp, bacter_id);
        } else {
            setInputNumber(valor);
            setInputText("");
            changeDataMicro(tipo, valor, bacter_temp, bacter_id);
        }
    }

    // //console.log(bacter, "esta es la bacteria");

    return (
        <>
            <Row className="border-top pt-1 mt-4">
                <Col sm="10">
                    <Row>
                        <Col sm="3">
                            <label>Tinción de gram</label>
                            <Select
                                isDisabled={bacter.userValidate === null ? false : true}
                                menuPlacement={"top"}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="gram"
                                options={[
                                    { "value": "-1", "label": "Selecciona una opción" },
                                    { "value": "POSITIVO", "label": "POSITIVO" },
                                    { "value": "NEGATIVO", "label": "NEGATIVO" },
                                    { "value": "N/A", "label": "N/A" },
                                ]}
                                value={inputGram}
                                onChange={(e) => handleValueGram(e)}
                                placeholder={"Selecciona el gram"}
                                theme={
                                    (theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#898989'
                                        }
                                    })
                                }
                            />
                        </Col>
                        <Col sm="4">
                            <label>Microorganismo</label>
                            <Select
                                isDisabled={bacter.userValidate === null ? false : true}
                                menuPlacement={"top"}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="id_bacterium"
                                options={bacteriumsOptions}
                                value={inputBacter}
                                onChange={(e) => changeDataMicro("id_bacterium", e.value, bacter.id_temp, bacter.id_bacterium, e.label)}
                                placeholder={"Seleccionar microorganismo"}
                                theme={
                                    (theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#898989'
                                        }
                                    })
                                }
                            />
                        </Col>
                        <Col sm="2">
                            <label>Cantidad número</label>
                            <Input disabled={bacter.userValidate === null ? false : true} value={inputNumber} onChange={(e) => changeInput("cantidad_unidades", e.target.value, bacter.id_temp, bacter.id_bacterium)} />
                        </Col>
                        <Col sm="3">
                            <label>Cantidad texto</label>
                            <Select
                                isDisabled={bacter.userValidate === null ? false : true}
                                menuPlacement={"top"}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="gram"
                                options={[{ "value": "-1", "label": "Selecciona una opción" }, { "value": "Escasa cantidad", "label": "Escasa cantidad" }, { "value": "Regular cantidad", "label": "Regular cantidad" }, { "value": "Abundante cantidad", "label": "Abundante cantidad" }]}
                                value={inputText}
                                onChange={(e) => changeInput("cantidad_texto", { "value": e.value, "label": e.label }, bacter.id_temp, bacter.id_bacterium)}
                                placeholder={"Selecciona el gram"}
                                theme={
                                    (theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#898989'
                                        }
                                    })
                                }
                            />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col sm="12">
                            <label>Mecanismos de resistencia</label>
                            <Select
                                isDisabled={bacter.userValidate === null ? false : true}
                                menuPlacement={"top"}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="id_mic_identification_test"
                                options={identificationTests}
                                value={inputIdentificationTest}
                                onChange={(e) => cambioPrueba(e)}
                                placeholder={"Selecciona el mecanismo de resistencia"}
                                theme={
                                    (theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#898989'
                                        }
                                    })
                                }
                            />
                        </Col>
                    </Row>
                </Col>

                <Col sm="2">
                    <Row>
                        <Col sm="12">
                            <label>Acciones</label>
                            <br />
                            <ButtonGroup>
                                {
                                    (bacter.userValidate === null || bacter.userValidate === undefined) ?
                                        <Button size='xs' color='secondary' onClick={() => validateBacterium(idWorkOrderExam, idExam, bacter.id_mic_bacterium, "section")}>
                                            <i className="icofont icofont-ui-unlock"></i>
                                        </Button>
                                        :
                                        <>
                                            <Button size='xs' color='success' onClick={null}>
                                                <i style={{ cursor: "pointer" }} id={`tooltipRelaeseB-${bacter.id_mic_bacterium}`} className="icofont icofont-ui-lock text-white"></i>
                                                <Tooltip
                                                    placement={"top"}
                                                    isOpen={tooltipRelease}
                                                    target={"tooltipRelaeseB-" + bacter.id_mic_bacterium}
                                                    toggle={toggleTooltipRelease}
                                                >
                                                    {`Validado el ${bacter.userValidate.date_capture} por ${bacter.userValidate.full_name}`}
                                                </Tooltip>
                                            </Button>
                                        </>
                                }
                                {
                                    (bacter.userValidate === null || bacter.userValidate === undefined) ?
                                        <Button size='xs' color='danger' onClick={() => removeMicroAislado(idWorkOrderExam, "section", bacter.id_mic_bacterium === undefined ? bacter.id_temp : bacter.id_mic_bacterium, bacter.id_mic_bacterium === undefined ? true : false)}>
                                            <i className="icofont icofont-trash"></i>
                                        </Button> : ""
                                }
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Col >
            </Row >
            {
                useAntibiogram &&
                <InputAntibiogram
                    userValidate={bacter.userValidate}
                    idWorkOrderExam={idWorkOrderExam}
                    bacterias={micbacteriasSelected.filter(x => x.id_bacterium === bacter.id_bacterium)}
                    microAntibiograms={microAntibiograms.filter(x => x.id_mic_bacterium === bacter.id_bacterium)}
                    addAntibiogram={addAntibiogram}
                    changeDataAntibiogram={changeDataAntibiogram}
                />
            }
        </>
    )
}
