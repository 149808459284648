import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import 'moment/locale/es';
import moment from 'moment';
import "react-datetime/css/react-datetime.css";
import { ExportInvoiceDefaultValues } from '../../data/const';
import { FilterTypeBillingCompany, IdMoralPerson, IdPhysicalPerson, MoralPerson, PayMethodPpd, PayMethodPue } from '../../constant/billing';

export const useInvoiceService = () => {

    const history = useHistory();
    let id_branch = localStorage.getItem('branchId');

    //DASHBOARD INVOICE
    const { id_work_order_r, id_patient_r,_id_company_r, request_id_invoice } = useParams();
    //GLOBAL VARIABLES
    const [loading, setLoading] = useState(false);
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [loadingExtraPatients, setLoadingExtraPatients] = useState(false);
    const [loadingFilterWorkOrders, setLoadingFilterWorkOrders] = useState(false);
    const [method, setMethod] = useState("");

    //#region MODALS
    const [modalTitle, setModalTitle] = useState("");

    const [modalBusinessName, setModalBusinessName] = useState(false);
    const [modalPatient, setModalPatient] = useState(false);
    const [modalPreviewInvoice, setModalPreviewInvoice] = useState(false);
    const [modalExtraPatients, setModalExtraPatients] = useState(false);
    const [modalListEgress, setModalListEgress] = useState(false);

   
    const toggleModalBusinessName = () => {
        setModalBusinessName(!modalBusinessName);
    }

    const toggleModalPatient = () => {
        setModalPatient(!modalPatient);
    }

    const toggleModalPreviewInvoice = () => {
        setModalPreviewInvoice(!modalPreviewInvoice);
    }

    const toggleModalExtraPatients = () => {
        setModalExtraPatients(!modalExtraPatients);
    }

    const toggleModalListEgress = () => {
        setModalListEgress(!modalListEgress);
    }

    //#endregion

    //#region SHOW DISPLAY MESSAGE
    const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam, sweetConfirmationChangeClient, sweetConfirmationCancel, , sweetValidDate] = useSweetAlert();
    //#endregion

    //#region INVOICE SERVICE

    //#region MODELS
    const [filterTypeClient, setFilterTypeClient] = useState("1");

    const [viewSearchPatient, setViewSearchPatient] = useState(true);
    const [viewSearchClient, setViewSearchClient] = useState(true);
    const [viewFilterNims, setViewFilterNims] = useState(true);

    const [listPayForms, setListPayForms] = useState([]);
    const [listPayMethods, setListPayMethods] = useState([]);
    const [listCoins, setListCoins] = useState([]);
    const [listCommercialLines, setListCommercialLines] = useState([]);
    const [listTaxRegime, setListTaxRegime] = useState([]);
    const [listUseCfdi, setListUseCfdi] = useState([]);
    const [listPeriodicities, setListPeriodicities] = useState([]);
    const [listNims, setListNims] = useState([]);
    const [listExamsInvoice, setListExamsInvoice] = useState([]);
    const [listInvoices, setListInvoices] = useState([]);
    const [listCfdiMonths, seTlistCfdiMonths] = useState([]);
    const [listTaxes, setListTaxes] = useState([]);
    const [listTempExtraPatients, setListTempExtraPatients] = useState([]);
    const [listFilterPayMethods, setListFilterPayMethods] = useState([]);
    const [listTypeRelationship, setListTypeRelationship] = useState([]);
    const [branch, setBranch] = useState([]);

    const [subtotalInvoice, setSubtotalInvoice] = useState(0.00);
    const [totalDiscountsInvoice, setTotalDiscountsInvoice] = useState(0.00);
    const [totalTaxInvoice, setTotalTaxInvoice] = useState(0.00);
    const [totalRetentions, setTotalRetentions] = useState(0.00);
    const [totalInvoice, setTotalInvoice] = useState(0.00);

    const [iconCoin, setIconCoin] = useState("");
    const [filterWorkOrder, setFilterWorkOrder] = useState({});
    const [filterInvoices, setFilterInvoices] = useState({});
    const [informationBusinessName, setInformationBusinessName] = useState({});
    const [informationPatient, setInformationPatient] = useState({});

    const [typeMethodDiscount, setTypeMethodDiscount] = useState(1);
    const [amountDiscount, setAmountDiscount] = useState(0.0);
    const [isGlobalDiscount, setIsGlobalDiscount] = useState(1);

    const [disabledSaveButton, setDisabledSaveButton] = useState(false);

    const [subtotalC, setSubtotalC] = useState(0.00);
    const [totalTaxC, setTotalTaxC] = useState(0.00);
    const [totalC, setTotaC] = useState(0.00);


    
    const [subtotalN, setSubtotalN] = useState(0.00);
    const [totalTaxN, setTotalTaxN] = useState(0.00);
    const [totalN, setTotaN] = useState(0.00);

    let _valid = (id_patient_r !== undefined && id_work_order_r !== undefined) ? parseInt(id_patient_r) : 0;






    const [formInvoice, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        id_invoice: null,
        id_patient: _valid,
        id_company: 0,
        id_company_business_name: 0,
        id_commercial_line: 0,
        id_pay_form: 0,
        id_method_pay: {
            value: ExportInvoiceDefaultValues.defaultCodePayMethod,
            label: ExportInvoiceDefaultValues.defaultPayMethod,
        },
        id_coin: 2,
        id_use_cfdi: 0,
        id_periodicity: 0,
        id_month: 0,
        id_type_releationship: ExportInvoiceDefaultValues.defaultTypeReleationship,
        type_document: "I",
        tax_iva: 1,
        expedition_place: 0,
        payment_conditions: "",
        comments_invoice: "",
        is_general_public: false,
        information_general_public: {
            name_general_public: "PUBLICO EN GENERAL",
            rfc_general_public: "XAXX010101000",
            cp_general_public: "01000",
            id_tax_regime_general_public: 0,
            code_tax_regime_general_public: "",
            tax_regime_general_public: ""
        },
        send_invoiced: true,
        type_invoice_company:"1",
        description:"Analisis clinicos",
        name_tax_regime_issuing:"",
        is_general_public_type_description: "1",
        date_payment: "",
        date_1: "",
        date_2: "",
        class_product_code_service: 0


    });

    const {
        id_invoice, id_patient, id_company, id_company_business_name, id_commercial_line, id_pay_form, id_method_pay, id_coin, id_use_cfdi, id_periodicity, id_month, id_type_releationship,
        type_document, tax_iva, expedition_place, payment_conditions, is_general_public, information_general_public, comments_invoice,type_invoice_company,description,
        is_general_public_type_description,date_payment,date_1,date_2,class_product_code_service
    } = formInvoice;

    const [validationsInvoice, setValidationsInvoice] = useState({
        _id_patient_valid: false,
        _id_company_valid: false,
        _id_company_business_name_valid: false,
        _id_commercial_line_valid: false,
        _id_use_cfdi_valid: false,
        _expedition_place_valid: false,
        _id_method_pay_valid: false,
        _id_pay_form_valid: false,
        _id_coin_valid: false,
        _tax_iva_value: false,
        _list_exams_invoice_valid: false,
        _id_periodicity_valid: false,
        _id_month_valid: false
    });


    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        handlePayForms();
        handleListCoins();
        handleGetListCommercialLines();
        handleSetTypeMethods();
        handleGetListPeriodicity();
        handleSetTaxes();
        handleGetListFilterPayMethods();
        handleGetTypeRelationship();
    }, []);

    //DEFAULT COINS
    useEffect(() => {
        if (listCoins.length > 0) {
            handleUpdateValues({
                ...formInvoice,
                id_coin: listCoins[1]
            });
        }
    }, [listCoins]);

    //INVOICE DASHBOARD
    useEffect(() => {
        if (id_patient_r !== undefined && id_work_order_r !== undefined) {
            handleChangeNim({
                value: id_work_order_r
            });
        }
    }, [id_work_order_r])

    useEffect(() => {
        if (_id_company_r > 0 ) {
            handleChangeTypeClient("2");
        }
    }, [_id_company_r])    

    useEffect(() => {
        getBranches();
    }, []);

    useEffect(() => {        
        if (request_id_invoice !== undefined) {
            getRequestInvoice(request_id_invoice);
        }
    }, [request_id_invoice]);

    console.log(formInvoice);
    
    //#endregion    

    //#region METHODS

    const getRequestInvoice = async (_id_invoice) => {
        let listTmp = await _handleRequest("GET",`Invoice/GetInvoiceById/${_id_invoice}`, "Editar facturas");

        if (listTmp !== null) {
            setFilterTypeClient(listTmp.filter_type_billing);
           

            //UPDATE FORM
            handleUpdateValues({
                ...formInvoice,
                id_invoice: listTmp.id_invoice,
                id_patient: listTmp.id_patient === null ? 0 : listTmp.id_patient,
                id_company: listTmp.id_company === null ? 0 : {
                    value: listTmp.id_company,
                    label: listTmp.name_company
                },
                id_company_business_name: listTmp.id_company_business_name === null ? 0 : listTmp.id_company_business_name,
                id_commercial_line: listTmp.id_commercial_line === null ? 0 : {
                    value: listTmp.id_commercial_line,
                    label: listTmp.name_commercial_line,
                    rfc: listTmp.rfc_issuing,
                    cp: listTmp.expedition_place,
                    id_tax_regime: listTmp.id_tax_regime_issuing,
                    code_tax_regime: listTmp.name_tax_regime_issuing,
                    name_tax_regime: listTmp.name_tax_regime_receptor,
                    type_person: listTmp.type_person == MoralPerson? true: false
                },
                id_pay_form: listTmp.id_pay_form === null ? 0 : {
                    value: listTmp.id_pay_form,
                    label: listTmp.name_pay_form
                },
                id_method_pay: listTmp.name_pay_method === null 
                ? {
                    value: ExportInvoiceDefaultValues.defaultCodePayMethod,
                    label: ExportInvoiceDefaultValues.defaultPayMethod,
                  }
                : {
                    value: listTmp.name_pay_method,
                    label: listTmp.name_pay_method === ExportInvoiceDefaultValues.defaultCodePayMethod 
                        ? PayMethodPue
                        : PayMethodPpd ,
                },
                id_coin: listTmp.id_coin === null ? 2 : { 
                    label: listTmp.name_coin,
                    value: listTmp.id_coin,
                    code: listTmp.code_coin,
                    decimals: listTmp.decimals_coin,
                    icon: listTmp.icon_coin
                },
                id_use_cfdi: listTmp.id_use_cfdi === null ? 0 : {
                    value: listTmp.id_use_cfdi,
                    label: listTmp.code_use_cfdi + " " + listTmp.name_use_cfdi
                },
                id_periodicity: listTmp.id_periodicity === null ? 0 : {
                    value: listTmp.id_periodicity,
                    label: listTmp.code_periodicity + " " + listTmp.name_periodicity
                },
                id_month: listTmp.id_month === null ? 0 : {
                    value: listTmp.id_month,
                    label: listTmp.code_month + " " + listTmp.name_month
                },
                tax_iva: {
                    value: "0.160000",
                    label: "16 %",
                    code: "002",
                    factor: "Tasa"
                },
                expedition_place: listTmp.expedition_place === null ? 0 : listTmp.expedition_place,
                payment_conditions: listTmp.payment_conditions === null ? "" : listTmp.payment_conditions,
                comments_invoice: listTmp.additional_comments === null ? "" : listTmp.additional_comments,
                is_general_public: listTmp.is_general_public,
                send_invoiced: listTmp.send_invoiced,
                type_invoice_company: listTmp.type_visualization_document,
                description: listTmp.global_description === null ? "Analisis clinicos" : listTmp.global_description,
                name_tax_regime_issuing: listTmp.name_tax_regime_issuing === null ? "" : listTmp.name_tax_regime_issuing,
            });

            if (listTmp.filter_type_billing == FilterTypeBillingCompany) {
                setInformationBusinessName({
                    id_tax_regime_business_name: listTmp.id_tax_regime,
                    business_name: listTmp.name_receptor,
                    rfc_business_name: listTmp.rfc,
                    cp_business_name: listTmp.postal_code,
                    email_business_name: listTmp.email_receptor,
                    tax_regime_business_name: listTmp.name_tax_regime_receptor,
                    type_person_business_name: listTmp.type_person == MoralPerson ? IdMoralPerson : IdPhysicalPerson,
                    code_tax_regime_business_name: listTmp.code_tax_regime_receptor
                });
            }

            let _decimals = listTmp.decimals_coin;

            if (listTmp.invoiceBodyDetails.length > 0) {
                let listTest = [];

                listTmp.invoiceBodyDetails.forEach(obj => {
                    listTest.push({
                        id_invoice_detail: obj.id_invoice_detail,
                        id_invoice: obj.id_invoice,
                        id_work_order: obj.id_work_order,
                        id_work_order_exam: obj.id_work_order_exam,
                        id_work_order_profile: obj.id_work_order_profile,
                        id_exam: obj.id_exam,
                        id_profile: obj.id_profile,
                        id_price: obj.id_price,
                        id_profile_price: obj.id_profile_price,
                        id_profile_curve_price: obj.id_profile_curve_price,
                        id_class_product_service: obj.id_class_product_service,
                        id_product_service: obj.id_product_service === null ? "" : obj.id_product_service,
                        id_unit: obj.id_unit,
                        class_product_code_service: obj.product_code_service,
                        product_code_service: "",
                        identification_code: obj.identification_code,
                        code_unit: obj.code_unit,
                        name_unit: obj.name_unit,
                        nim: obj.nim,
                        name: obj.description,
                        price: parseFloat(obj.price.toFixed(_decimals)),
                        price_urgent: parseFloat(obj.price_urgent.toFixed(_decimals)),
                        price_tax: parseFloat(obj.price_tax.toFixed(_decimals)),
                        retention: obj.retention, 
                        total_discount: parseFloat(obj.total_discount.toFixed(_decimals)),
                        name_patient: obj.name_patient,
                        listTest: [],
                        is_general_public: obj.is_general_public,
                       // name: obj.name
                       is_deleted: false
                    })
                });

                setListExamsInvoice([...listTest]);
            }


            // handleSetTaxes();
        }
    }

    const handleChangeTypeInvoice = (typeInvoice) => {
        handleUpdateValues({
            ...formInvoice,
            type_document: typeInvoice
        });

        if (typeInvoice === "P") {
            handleChangeTypeClient("2");
        }
    }

    const handleChangeTypeClient = (typeClient) => {
     
        setFilterTypeClient(typeClient);

        //ARRAY´S RESET BECOUSE DEPEND TYPE PERSON
        setListUseCfdi([]);
        setListNims([]);
        setListExamsInvoice([]);
        seTlistCfdiMonths([]);

        setSubtotalInvoice(0.00);
        setTotalDiscountsInvoice(0.00);
        setTotalTaxInvoice(0.00);
        setTotalInvoice(0.00);
        setIconCoin("");

        setFilterWorkOrder({});
        setInformationPatient({});
        setViewFilterNims(false);

        setValidationsBusinessName({
            id_tax_regime_business_name_valid: false,
            business_name_valid: false,
            cp_business_name_valid: false,
            rfc_business_name_valid: false,
        });

        setValidationsPatient({
            id_tax_regime_patient_valid: false,
            rfc_patient_valid: false,
            cp_patient_valid: false
        });

        if (typeClient === "3") {
            setViewFilterNims(false);
        }
        else {
            setViewFilterNims(true);
        }
    }

    const handlePayForms = async () => {
        let listTmp = await _handleRequest("GET", `PayMethod/List?visibility_work_order=false&visibility_invoice=true`, "Formas de pago");

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach(obj => {
                list.push({
                    label: obj.abbreviation + " - " + obj.name,
                    value: obj.id_pay_method,
                    abbreviation: obj.abbreviation
                });
            });

            setListPayForms(list);
        }
    }

    const handleListCoins = async () => {
        let listTmp = await _handleRequest("GET", "CfdiCatalog/ListCoins", "Listado de monedas");

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach(obj => {
                list.push({
                    value: obj.id_coin,
                    label: obj.code + " " + obj.name,
                    code: obj.code,
                    decimals: obj.decimals,
                    icon: obj.icon
                });
            });

            setListCoins(list);
        }
    }

    const handleSetTypeMethods = () => {
        setListPayMethods([
            {
                value: "PUE",
                label: "(PUE) PAGO EN UNA SOLA EXHIBICIÓN",
            },
            {
                value: "PPD",
                label: "(PPD) PAGO EN PARCIALIDADES O DIFERIDO"
            }
        ]);
    }

    const handleSetTaxes = () => {
        setListTaxes([
            {
                value: "0.160000",
                label: "16 %",
                code: "002",
                factor: "Tasa"
            },
            {
                value: "0.080000",
                label: "8 %",
                code: "002",
                factor: "Tasa"
            },
            {
                value: "0.000000",
                label: "No objeto a impuestos",
                code: "000",
                factor: "Tasa"
            },
        ]);

        handleUpdateValues({
            ...formInvoice,
            tax_iva: {
                value: "0.160000",
                label: "16 %",
                code: "002",
                factor: "Tasa"
            },
        });
    }

    const handleGetListCommercialLines = async () => {
        let listTmp = await _handleRequest("GET", "CommercialLine/List", "Razón social");
        console.log(listTmp);

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach(obj => {
                list.push({
                    value: obj.id_commercial_line,
                    label: obj.long_name,
                    rfc: obj.rfc,
                    cp: obj.cp,
                    id_tax_regime: obj.id_tax_regime,
                    code_tax_regime: obj.code_tax_regime,
                    name_tax_regime: obj.name_tax_regime,
                    type_person: obj.type_person
                });
            });
            setListCommercialLines(list);
        }
    }

    const handleChangeCommercialLines = (e) => {
        handleUpdateValues({
            ...formInvoice,
            id_commercial_line: e,
            expedition_place: e.value !== undefined ? e.cp : 0
        });
    }

    const handleGetListUseCfdi = async (_id_tax_regime, _physical_person = false) => {
        const listTmp = await _handleRequest("GET", `CfdiCatalog/ListUseCfdi/${_id_tax_regime}/${_physical_person}`);

        let list_tmp = [];
        

        if (listTmp !== null && listTmp.length > 0) {

            listTmp.forEach(useCfdi => {
                list_tmp.push({
                    value: useCfdi.id_use_cfdi,
                    label: useCfdi.code + " - " + useCfdi.name,
                    code: useCfdi.code
                });
            });

            setListUseCfdi(list_tmp);
        }
    }

    const handleGetListPeriodicity = async () => {
        const listTmp = await _handleRequest("GET", "CfdiCatalog/ListCfdiPeriodicities", "Periodicidad");

        let _list = [];

        if (listTmp !== null && listTmp.length > 0) {
            listTmp.forEach(periodicity => {
                _list.push({
                    value: periodicity.id_periodicity,
                    label: periodicity.code + " - " + periodicity.name,
                    code: periodicity.code
                });
            });

            setListPeriodicities(_list);
        }
    }

    const handleGetListNIm = async (id_patient = null, id_company = null, isgeneral_public = null) => {

        let alternative_url = "";

        if (id_patient !== null) {
            alternative_url = "?id_patient=" + id_patient;
        }
        else if (id_patient === null && id_company !== null) {
           
            alternative_url = "?id_company=" + id_company.value;
        }
        else if (id_patient === null && id_company === null && isgeneral_public !== null) {
            alternative_url = "?is_general_pubic="+isgeneral_public;
        }

        if (type_document === "P" || type_document === "E") {
            const listTmp = await _handleRequest("GET", `Invoice/GetFilterInvoices/${type_document}${alternative_url}`, "Facturas");

            if (listTmp !== null && listTmp.length > 0) {
                let _list = [];

                listTmp.forEach(_invoice => {
                    _list.push({
                        label: `Factura ${_invoice.folio} | ${_invoice.date} | ${_invoice.total} | ${_invoice.code_coin}`,
                        value: _invoice.id_invoice,
                        id_pay_form: _invoice.id_pay_form,
                        folio: _invoice.folio,
                        date: _invoice.date,
                        total: _invoice.total,
                        subtotal: _invoice.subtotal,
                        totalTax: _invoice.totalTax,
                        code_coin: _invoice.code_coin,
                        code_pay_form: _invoice.code_pay_form,
                        name_pay_form: _invoice.name_pay_form,
                        uuid: _invoice.uuid,
                        is_paid: _invoice.is_paid
                    });
                });

                setListNims(_list);
            }

            if (type_document == "E") {
                toggleModalListEgress();
                setModalTitle("Selecciona un CFDI para crear una nota de crédito");
            }
        }        
        else {
            const listTmp = await _handleRequest("GET", `Invoice/GetFilterWorkOrdersInvoice${alternative_url}`, "Filtrar Nim");

            if (listTmp !== null && listTmp.length > 0) {

                let list = [];

                listTmp.forEach(obj => {
                    list.push({
                        label: obj.nim + " / " + obj.name_branch + " / " + obj.date_work_order + (id_company !== null ? " / " + obj.name_patient : ""),
                        value: obj.id_work_order
                    });
                });

                setListNims(list);
            }
        }
    }

    const handleGetCfdiMonths = async (_idPeriodicity = null) => {
        let _alternativeUrl = "";

        if (_idPeriodicity !== null) {
            _alternativeUrl = "?id_periodicity=" + _idPeriodicity;
        }

        const listTmp = await _handleRequest("GET", "CfdiCatalog/ListMonths" + _alternativeUrl, "Meses");

        if (listTmp !== null && listTmp.length > 0) {

            let _list = [];

            listTmp.forEach(obj => {
                _list.push({
                    value: obj.id_month,
                    label: obj.code + " - " + obj.name,
                    id_periodicity: obj.id_periodicity,
                    code: obj.code
                });
            });

            seTlistCfdiMonths(_list);
        }
    }

    const handleGetListFilterPayMethods = async () => {

        const _listFiltersPays = await _handleRequest("GET", "PayMethod/List?visibility_work_order=true", "Métodos de pago");

        if (_listFiltersPays != null && _listFiltersPays.length > 0) {
            let _list = [];

            _listFiltersPays.forEach(_pay => {
                _list.push({
                    value: _pay.id_pay_method,
                    label: _pay.name
                });
            });

            setListFilterPayMethods(_list);
        }
    }

    const handleChangePeriodicity = (e) => {

        if (e.value !== undefined) {

            handleGetCfdiMonths((e.code === "05" ? e.value : null));
            handleFilterTaxRegimeGeneralPublic(e);
            handleUpdateValuesGenPub({
                date_periodicity: "",
                week_from_periocity: "",
                week_to_periocity: "",
                id_branchI : 0,
                idFilterPayMethod: 1
            })
        }
    }

    const handleChangeTypeMethods = (e, nameInput) => {
        if (e.value !== undefined) {

            if (e.value === "PPD") {
                let findPayForm = listPayForms.find(x => x.abbreviation === '99');

                if (findPayForm !== undefined) {
                    handleUpdateValues({
                        ...formInvoice,
                        id_pay_form: findPayForm,
                        id_method_pay: e
                    });
                }
            }
            else {
                handleSelectValues(e, nameInput);
            }
        }
    }

    //#region type invoice
    
    const handleChangeInvoiceCompany = (_typeInvoiceC) => {
        handleUpdateValues({
            ...formInvoice,
            type_invoice_company: _typeInvoiceC
        });
    }

    //#endregion type invoice

    //!CONFIGURATION CODE CONCEPTS INVOICES
    const handleChangeProductCode = (e, _id_work_order_exam) => {

        let _list = listExamsInvoice;
        let _value = e.target.value;
        let _name_input = e.target.name;

        let _find_mic = _list.find(x => x.id_work_order_exam === _id_work_order_exam);
    
        if (_find_mic !== undefined) {
            switch (_name_input) {
                case "class_product_code_service":
                    _find_mic.class_product_code_service = _value;
                    break;
                default:
                    break;
            }
        }
       setListExamsInvoice([..._list]);
    }

    //#region type description 

    const [dataFech, setDataFech] = useState("")
    const [typeGlobal, setTypeGlobal] = useState("")
    const [dataIni, setDataIni] = useState("");
    const [dataEnd, setDataEnd] = useState("");
    const [dataEnd1, setDataEnd1] = useState("");


    
    const handleChangeDescription= (_typeDescription) => {

        let _validFech = dataIni == "N" ? dataEnd1 : dataIni
        let _validFech2 = dataEnd
        let _validEnd = dataIni === "N" ? _validFech2 :_validFech

        let _description_global = "Ventas";
        let _description_fech =  `ANALISIS REALIZADOS DEL ${_validFech} AL ${_validEnd} `  ;
        let _findDescription = _typeDescription  === "1" ? _description_global  : _description_fech;
        
        console.log(_validFech);
        console.log(_validEnd);
    


        handleUpdateValues({
            ...formInvoice,
            is_general_public_type_description: _typeDescription,
            date_1:_validFech,
            date_2:_validEnd

        });
        setTypeGlobal(_findDescription)

    }

    //#endregion type description


    console.log(dataIni);
    console.log(dataEnd);



    //#region Filter company
    const [formFech, handleInputChangeFech, resetFech, handleUpdateValuesFech, handleSelectValuesFech] = useForm({
        dateFrom: "",
        dateTo: "", 
    });

    const {
        dateFrom, dateTo
    } = formFech;

    const [id_company_filter, setId_company_filter] = useState(0);
    const [view_filter_company, setView_filter_company] = useState(false);
    
    const handleViewCompany= () => {
     
     setView_filter_company(true);
        
    }

    const handleCloseFilterCompany  = () => {
        setView_filter_company(!view_filter_company);
    }
    //#endregion Filter company

    const [dicountGlobal, setDicountGlobal] = useState(0);
    const [totalGlobal, setTotalGlobal] = useState(0);
    const [codeGlobal, setCodeGlobal] = useState("");


    const handleChangeNim = async (e, isFiltered = false) => {
        setLoadingFilterWorkOrders(true);

        let _idWorkOrder = 0;
        if (e === 0) {
             _idWorkOrder = e;            
        }
        else {
            setFilterWorkOrder(e);

            if (e.value === undefined) {
                createSweet("info", "info", "Seleccionar nim", "Selecciona otra opción");
                return;
            }
            _idWorkOrder = e.value;
        }

        let _decimals = 2;    

        let _filter = id_coin.value === undefined ? id_coin : id_coin.value
        let _typePersonCommercialLine = id_commercial_line.type_person;

        let _validInvoiceTypePersonCommercailLine  = _typePersonCommercialLine === undefined ? true : _typePersonCommercialLine
      
        let listTmpNims = listNims;

        if (_idWorkOrder !== 0) {
            listTmpNims.map(obj => {
                if (_idWorkOrder === obj.value) {
                    obj.isDisabled = true;
                }
    
                return obj;
            });
        }

        let _routeGetWorkOrder = 'Invoice/GetDetailWorkOrderInvoice/';

        if (isFiltered) {

            let mfrom = (dateFrom) ? moment(dateFrom).format("YYYY-MM-DD") : "";
            let mto = (dateTo) ? moment(dateTo).format("YYYY-MM-DD") : "";

            _routeGetWorkOrder += '0/ADMISION/'+_filter+'/'+_validInvoiceTypePersonCommercailLine+`?dateFrom=${mfrom}&dateTo=${mto}&id_company=`+id_company_filter;

        }
        else {
            _routeGetWorkOrder += _idWorkOrder+'/ADMISION/'+_filter+'/'+_validInvoiceTypePersonCommercailLine;
        }

        const listTmp = await _handleRequest("GET", _routeGetWorkOrder, "Detalle admisión");

        
        if (listTmp !== null && listTmp.length > 0) {

            let _array = listExamsInvoice;

            listTmp.forEach(obj => {
                console.log(obj);
                _array.push({
                    id_invoice_detail: null,
                    id_invoice: null,
                    id_work_order: obj.id_work_order,
                    id_work_order_exam: obj.id_work_order_exam,
                    id_work_order_profile: obj.id_work_order_profile,
                    id_exam: obj.id_exam,
                    id_profile: obj.id_profile,
                    id_price: obj.id_price,
                    id_profile_price: obj.id_profile_price,
                    id_profile_curve_price: obj.id_profile_curve_price,
                    id_class_product_service: obj.id_class_product_service,
                    id_product_service: obj.id_product_code_service === null ? "" : obj.id_product_code_service,
                    id_unit: obj.id_unit,
                    class_product_code_service: obj.class_product_code_service,
                    product_code_service: obj.product_code_service,
                    identification_code: obj.identification_code,
                    code_unit: obj.code_unit,
                    name_unit: obj.name_unit,
                    nim: obj.nim,
                    name: obj.name,
                    price: parseFloat(obj.price.toFixed(_decimals)),
                    price_urgent: parseFloat(obj.price_urgent.toFixed(_decimals)),
                    price_tax: parseFloat(obj.price_tax.toFixed(_decimals)),
                    retention: obj.retention, 
                    total_discount: parseFloat(obj.total_discount.toFixed(_decimals)),
                    name_patient: obj.name_patient,
                    listTest: [],
                    is_general_public: false,
                    is_deleted: false
                });
            });

            setListExamsInvoice([..._array]);
            setListNims(listTmpNims);
            setFilterWorkOrder({});
        }
        setLoadingFilterWorkOrders(false);
    }

    //#region EXAMS AND PROFILES GROUP BY 

    useEffect(() => {
        let _InvoiceGlobal = listExamsInvoice
        let _price = 0;
        let _discount =0;
        let _decimal = 2;
        let _codeGlob = "";
        
        _InvoiceGlobal.forEach(_find => {

            if (!_find.is_deleted) {
                _price += parseFloat(_find.price.toFixed(_decimal));
                _discount += parseFloat(_find.total_discount.toFixed(_decimal));
                _codeGlob = _find.class_product_code_service
            }
        });

        setDicountGlobal(_discount);
        setTotalGlobal(_price);
        setCodeGlobal(_codeGlob);

    }, [listExamsInvoice])

    const [examInvoice, setExamInvoice] = useState([])
    const [priceInvoice, setPriceInvoice] = useState([])

    useEffect(() => {
        if ( type_invoice_company === "3" ) {

        let _InvoiceExams = listExamsInvoice;
        console.log(_InvoiceExams);
        let _nuevoObjeto = {};
        let _listExams = [];
        let _decimal = 2;
    
        _InvoiceExams.forEach(x => {
            if (!x.is_deleted) {

                if (x.id_exam != null) {
                    if (!_nuevoObjeto.hasOwnProperty('e-'+x.id_exam)) {
                        _nuevoObjeto['e-'+x.id_exam] = {
                            IdProfile: null,
                            IdExam: x.id_exam,
                            Name: x.name,
                            Amount: 0,
                            AmountCapture: 0,
                            Count: 0 ,
                            class_product_code_service: x.class_product_code_service

                        };
                    }
                }   
                else if(x.id_profile != null){
                    if (!_nuevoObjeto.hasOwnProperty('p-'+x.id_profile)) {
                        _nuevoObjeto['p-'+x.id_profile] = {
                            IdProfile: x.id_profile,
                            IdExam: null,
                            Name: x.name,
                            Amount: 0,
                            AmountCapture: 0,
                            Count: 0 ,
                            class_product_code_service: x.class_product_code_service

                        }
                    }
                }

                let propertyId = x.id_exam != null ? 'e-'+x.id_exam : 'p-'+x.id_profile;

                _nuevoObjeto[propertyId].Amount += parseFloat(x.price.toFixed(_decimal));
                _nuevoObjeto[propertyId].AmountCapture += parseFloat(x.total_discount.toFixed(_decimal));
                _nuevoObjeto[propertyId].Count++; 
            }
        });

        for (let _prop in _nuevoObjeto) {
            _listExams.push({
                NameExamAndProfile: _nuevoObjeto[_prop].Name,
                idProfile: _nuevoObjeto[_prop].IdProfile,
                idExam: _nuevoObjeto[_prop].IdExam,
                price: parseFloat(_nuevoObjeto[_prop].Amount).toFixed(_decimal),
                priceDiscount: parseFloat(_nuevoObjeto[_prop].AmountCapture).toFixed(_decimal),
                amount: _nuevoObjeto[_prop].Count,
                code: _nuevoObjeto[_prop].class_product_code_service
            });
        }
 
        setPriceInvoice(_nuevoObjeto);
        setExamInvoice(_listExams);
            
        }
    }, [type_invoice_company]);


    //#endregion EXAMS AND PROFILES GROUP BY 

    const removeInvoice = (id_invoice, _id_invoice_detail = null, removePreinvoice = false) => {

        let _copy = listExamsInvoice;

        if (_id_invoice_detail !== null && removePreinvoice) {
            let _findInvoice = _copy.find(r => r.id_invoice_detail === _id_invoice_detail);
            
            if (_findInvoice !== undefined && _findInvoice.id_invoice_detail !== null) {
                _findInvoice.is_deleted = true;
            }
        }
        else {
            
            let _findInvoiceIndex = listExamsInvoice.findIndex(r => r.id_work_order === id_invoice);

            _copy.splice(_findInvoiceIndex, 1);            
        }

        setListExamsInvoice([..._copy])

    }
    
    const handleChangeInvoice = async (e) => {
        setFilterInvoices(e);

        if (e.value === undefined) {
            createSweet("info", "info", "Selecciona factura", "Selecciona otra opción");

            return;
        }

        if (id_coin.decimals === undefined) {
            createSweet("info", "info", "Selecciona una moneda para seguir continuando", "");
            setFilterInvoices({});
            return;
        }

        let listTmpNims = listNims;
        let _idInvoice = e.value;

        listTmpNims.map(_invoice => {
            if (_idInvoice === _invoice.value) {
                _invoice.isDisabled = true;
            }

            return _invoice;
        });

        const _response = await _handleRequest("GET", `Invoice/GetDetailInvoice/${_idInvoice}`, "Información de factura");

        if (_response !== null) {
            let _listInvoices = listInvoices;

            _listInvoices.push({
                id_invoice: _response.id_invoice,
                id_pay_method: _response.id_pay_method,
                id_coin: _response.id_coin,
                uuid: _response.uuid,
                serie: _response.serie,
                folio: _response.folio,
                date: _response.date,
                subtotal: _response.subtotal,
                total_taxes: _response.total_taxes,
                total: _response.total,
                code_coin: _response.code_coin,
                version: _response.version,
                code_pay_method: _response.code_pay_method,
                type_change: _response.type_change,
                name_type_method: _response.name_type_method,
                parciality_number: _response.parciality_number,
                previous_amount: _response.previous_amount,
                payment_amount: _response.payment_amount,
                static_payment_amount: _response.payment_amount,
                alert_payment_amount: false,
                outside_balance: _response.outside_balance
            });

            setListInvoices([..._listInvoices]);
            setListNims(listTmpNims);
            setFilterInvoices({});
        }
    }

    const handleGetTypeRelationship = async (e) => {
        const _response = await _handleRequest("GET", `CfdiCatalog/ListRelationships`, "Tipo de relación");

        if (_response !== null && _response.length > 0) {

            let _list = [];

            _response.forEach(obj => {
                _list.push({
                    value: obj.id_type_releationship,
                    label: obj.code + " - " + obj.name,
                    code: obj.code
                });
            });

            setListTypeRelationship(_list);
        }
    }

    //BRANCH FILTER INVOICE
    const getBranches = async () => {
          let _listTemp = await _handleRequest("GET", "Branch/List", "Sucursal");
          if (_listTemp !== null && _listTemp.length > 0) {
              let _newBranches = [];
              _listTemp.forEach((_obj) => {
                  _newBranches.push({
                      value: _obj.id_branch,
                      label: _obj.name
                  });
              });
              setBranch(_newBranches);
          }
    }
 
    const handleGetListGeneralPub = async (codePeriodicity, codeMonth, dateFrom, dateTo, branch, filterPayMethod = null) => {
        setLoadingFilterWorkOrders(true);

        let _dateIni = dateFrom;
        let _dataEnd = dateTo;
        let _code = codePeriodicity;

        let _validPeriodicity = codePeriodicity == "01" ? _dateIni : "N"
        let _validPeriodicity2 =  codePeriodicity == _code ? _dataEnd : ""
        let _validPeriodicity3 =  codePeriodicity == _code ? _dateIni : ""

        setDataIni(_validPeriodicity)
        setDataEnd(_validPeriodicity2)
        setDataEnd1(_validPeriodicity3)
  
        let _filterBranch = branch === undefined ? 0 : branch

        let _decimals = 2;

        if (id_coin.decimals !== undefined) {
            _decimals = id_coin.decimals;
        }
        else {
            createSweet("info", "info", "Selecciona una moneda para seguir continuando", "");

            return;
        }

        const listTmp = await _handleRequest("GET", 
            `Invoice/GetDetailWorkOrderGeneralPub/${codePeriodicity}/${codeMonth}/${dateFrom}/${dateTo}?branch=${_filterBranch}${filterPayMethod !== null ? '&id_pay_method='+filterPayMethod : ''}`, 
            "Detalle admisión"
        );

        if (listTmp !== null && listTmp.length > 0) {
            let _array = [];

            let _description_global = "Ventas";
            let _description_fech =  `ANALISIS REALIZADOS DEL ${dataFech} AL ${dataFech} `  ;
            let _type_public = formInvoice.is_general_public_type_description;
            let _findDescription = _type_public  === "1" ? _description_global  : _description_fech;
      

            listTmp.forEach(obj => {
                _array.push({
                    id_invoice_detail: null,
                    id_invoice: null,
                    id_work_order: obj.id_work_order,
                    id_work_order_exam: null,
                    id_work_order_profile: null,
                    id_exam: null,
                    id_profile: null,
                    id_price: null,
                    id_profile_price: null,
                    id_profile_curve_price: null,
                    id_class_product_service: obj.id_product_code_service,
                    id_product_service: obj.id_product_code_service,
                    product_code_service: obj.product_code_service,
                    class_product_code_service: obj.product_code_service,
                    id_unit: obj.id_unit_code,
                    code_unit: obj.code_unit,
                    name_unit: obj.name_unit,
                    nim: obj.nim,
                    name_patient: obj.name_patient,
                    price: obj.price,
                    price_urgent: obj.price_urgent,
                    total_discount: obj.total_discount,
                    import: obj.import,
                    price_tax: obj.price_tax,                    
                    total: obj.total,
                    listTest: obj.listTest,
                    is_general_public: true
                });
            });

            setListExamsInvoice([..._array]);
            setTypeGlobal(_findDescription)

        }
        else {
            setListExamsInvoice([]);
        }

        setLoadingFilterWorkOrders(false);
    }

    const handlePreviewInvoice = () => {
        setLoadingPreview(true);

        setModalTitle("Previsualizar factura");
        toggleModalPreviewInvoice();

        setTimeout(() => {
            setLoadingPreview(false);
        }, 5000);
    }

    const handleClearValues = () => {
        setFilterTypeClient("1");

        setDisabledSaveButton(false);

        setViewSearchPatient(true);
        setViewSearchClient(true);
        setViewFilterNims(true);

        setListTaxRegime([]);
        setListUseCfdi([]);

        setListNims([]);
        setListExamsInvoice([]);
        setListInvoices([]);
        seTlistCfdiMonths([]);
        setListTempExtraPatients([]);

        setSubtotalInvoice(0.00);
        setTotalDiscountsInvoice(0.00);
        setTotalTaxInvoice(0.00);
        setTotalInvoice(0.00);

        setIconCoin("");
        setFilterWorkOrder({});
        setFilterInvoices({});

        setTypeMethodDiscount(1);
        setAmountDiscount(0.0);
        setIsGlobalDiscount(1);

        handleUpdateValues({
            id_invoice: null,
            id_patient: 0,
            id_company: 0,
            id_company_business_name: 0,
            id_commercial_line: 0,
            id_pay_form: 0,
            id_method_pay: {
                value: ExportInvoiceDefaultValues.defaultCodePayMethod,
                label: ExportInvoiceDefaultValues.defaultPayMethod,
            },
            id_coin: 0,
            id_use_cfdi: 0,
            id_periodicity: 0,
            id_month: 0,
            id_type_releationship: ExportInvoiceDefaultValues.defaultTypeReleationship,
            type_document: "I",
            tax_iva: {
                value: "0.160000",
                label: "16 %",
                code: "002",
                factor: "Tasa"
            },
            expedition_place: 0,
            payment_conditions: "",
            comments_invoice: "",
            is_general_public: false,
            information_general_public: {
                name_general_public: "PUBLICO EN GENERAL",
                rfc_general_public: "XAXX010101000",
                cp_general_public: "01000",
                id_tax_regime_general_public: 0,
                code_tax_regime_general_public: "",
                tax_regime_general_public: ""
            },
            send_invoiced: true,
            type_invoice_company:"1",
            description:"Analisis clinicos"
        });

        setValidationsInvoice({
            _id_patient_valid: false,
            _id_company_valid: false,
            _id_company_business_name_valid: false,
            _id_commercial_line_valid: false,
            _id_use_cfdi_valid: false,
            _expedition_place_valid: false,
            _id_method_pay_valid: false,
            _id_pay_form_valid: false,
            _id_coin_valid: false,
            _tax_iva_value: false,
            _list_exams_invoice_valid: false,
            _id_periodicity_valid: false,
            _id_month_valid: false
        });

        //----------------------------------------------------------------

        setInformationBusinessName({});

        handleUpdateValuesBusinessName({
            id_tax_regime_business_name: 0,
            business_name: "",
            rfc_business_name: "",
            cp_business_name: "",
            email_business_name: "",
            tax_regime_business_name: "",
            type_person_business_name: 2,
            code_tax_regime_business_name: ""
        });

        setValidationsBusinessName({
            id_tax_regime_business_name_valid: false,
            business_name_valid: false,
            cp_business_name_valid: false,
            rfc_business_name_valid: false,
        });

        //-------------------------------------------------------------------
        setSearchPatientState({});
        setSearchPatientExtra({});
        setInformationPatient({});

        setSearchPatientValue({});

        handleUpdateValuesPatient({
            id_tax_regime_patient: 0,
            name_patient: "",
            type_person_patient: 1,
            cp_patient: 0,
            tax_regime_patient: "",
            rfc_patient: "",
            code_tax_regime: "",
            business_name_patient: ""
        });

        setValidationsPatient({
            id_tax_regime_patient_valid: false,
            rfc_patient_valid: false,
            cp_patient_valid: false
        });

        //---------------------------------------------------
        setValidDateFrom("");
        setValidDateTo("");

        handleUpdateValuesGenPub({
            date_periodicity: "",
            week_from_periocity: "",
            week_to_periocity: "",
            id_branchI : 0,
            idFilterPayMethod: 1
        });
    }

    const handleChangePaymentAmount = (e, _id_invoice) => {

        let _number_decimals = id_coin.decimals;
        let _min_value = parseFloat(e.target.min);
        let _max_value = parseFloat(e.target.max);
        let _value = e.target.value === "" ? "" : e.target.valueAsNumber;
        let _text_alert = "";

        if (_value <= _min_value || _value > _max_value) {
            _text_alert = true;
        }

        let _listInvoices = listInvoices.map(_invoice => {
            if (_invoice.id_invoice === _id_invoice) {

                let _outside_balance = (_value !== "" ? _invoice.previous_amount - _value : 0);

                _outside_balance = format_trunc_number(_outside_balance, _number_decimals);

                _invoice.payment_amount = _value;
                _invoice.alert_payment_amount = _text_alert;
                _invoice.outside_balance = _outside_balance;
            }

            return _invoice;
        });

        setListInvoices([..._listInvoices]);
    }

    const handleSelectInvoice = (_id_invoice) => {
        let findInvoice = listNims.find(x => x.value === _id_invoice);
        let _copyInvoices = listInvoices;
        let _total = totalInvoice;
        let _subtotal = subtotalInvoice;
        let _totalTax = totalTaxInvoice;

        if (findInvoice !== null) {
            _copyInvoices.push({
                id_invoice: findInvoice.value,
                id_pay_form: findInvoice.id_pay_form,
                folio: findInvoice.folio,
                date: findInvoice.date,
                total: findInvoice.total,
                subtotal: findInvoice.subtotal,
                totalTax: findInvoice.totalTax,
                code_coin: findInvoice.code_coin,
                code_pay_form: findInvoice.code_pay_form,
                name_pay_form: findInvoice.name_pay_form,
                uuid: findInvoice.uuid,
                is_paid: findInvoice.is_paid,
                productCode: ExportInvoiceDefaultValues.defaultEgreesClaveProdServ,
                unitCode: ExportInvoiceDefaultValues.defaultCodeUnitEgrees,
                concept: ExportInvoiceDefaultValues.defaultConcepEgrees,
                discount: 0
            });
        
            _total += findInvoice.total;
            _subtotal += Math.trunc((findInvoice.subtotal) * 100) / 100;
            _totalTax += Math.trunc((findInvoice.totalTax) * 100) / 100;


            setListInvoices([..._copyInvoices]);

            setSubtotalInvoice(_subtotal.toFixed(2));            
            setTotalTaxInvoice(_totalTax.toFixed(2));
            setTotalInvoice(_total.toFixed(2));

            toggleModalListEgress();
            setModalTitle("");
        }
    }

    const handleChangeTypeDiscount = (e) => {
        setTypeMethodDiscount(e.target.value);

        if (parseInt(e.target.value) === 2) {
            setAmountDiscount(totalInvoice);

            handleCalculateAmount(0, true);
        }
    }    

    const handleChangeAmountDiscount = (e) => {
        let _value = (e.target.value == '' ? 0 : parseFloat(e.target.value));

        setAmountDiscount(e.target.value);

        if(handleCalculateAmount(_value))
        {
            createSweet("warning", "warning", "Descuento", "El monto del descuento no puede ser mayor.");
        }
    }

    const handleCalculateAmount = (amount = 0, is_global = false) => {
        let totalDiscount = 0;
        let existError = false;

        let _copyInvoices = listInvoices.map(_invoice => {
            
            if (is_global) {
                _invoice.discount = _invoice.total;
                totalDiscount += _invoice.total;
            }
            else {
                if (amount > _invoice.total) {
                    existError = true;                    
                }
                else {
                    _invoice.discount = amount;
                    totalDiscount += amount;
                }
            }

            return _invoice;
        })

        setListInvoices([..._copyInvoices]);
        setTotalDiscountsInvoice(totalDiscount);

        return existError;
    }

    const handleChangeGlobalDiscount = (e) => {
        setIsGlobalDiscount(e.target.value);
        setTypeMethodDiscount(1);
        setAmountDiscount(0);
    }

    const handleChangeConcept = (e, _id_invoice) => {
        let findInvoice = listInvoices.find(x => x.id_invoice === _id_invoice);
        let _copyInvoices = listInvoices;

        if (findInvoice !== null) {
            findInvoice.concept = e.target.value;

            setListInvoices([
                ..._copyInvoices                
            ]);
        }
    }

   
  
    const saveInvoiceHelen = async (send_invoiced = false) => {
        console.log("pasa");

        setDisabledSaveButton(true);

        if (validationInvoice()) {
            setDisabledSaveButton(false);
            return;
        }

        createSweet("info", "info", "Recopilando información, para validar!");

        let _id_patient = null;
        let _id_company = null;
        let _id_company_business_name = null;
        let _id_month = null;
        let _id_periodicity = null;
        let _date_1 = "2000-01-01";
        let _date_2 = "2000-01-01";

        let _rfc = "";
        let _id_tax_regime = 0;
        let _postal_code = 0;
        let _name_receptor = "";
        let _email_receptor = "";
        let _type_person_receptor = "";

        if (filterTypeClient === "1") {
            //PATIENT
            _id_patient = formInvoice.id_patient;
            _rfc = informationPatient.rfc_patient;
            _id_tax_regime = informationPatient.id_tax_regime_patient;
            _postal_code = informationPatient.cp_patient;
            _name_receptor = informationPatient.business_name;
            _email_receptor = informationPatient.email;
            _type_person_receptor = informationPatient.type_person_patient === 1 ? false : true;
        }
        else if (filterTypeClient === "2") {
            //COMPANY
            _id_company_business_name = formInvoice.id_company_business_name;
            _rfc = informationBusinessName.rfc_business_name;
            _id_tax_regime = informationBusinessName.id_tax_regime_business_name;
            _postal_code = informationBusinessName.cp_business_name;
            _id_company = formInvoice.id_company.value;
            _name_receptor = informationBusinessName.business_name;
            _email_receptor = informationBusinessName.email;
            _type_person_receptor = informationBusinessName.type_person_business_name === 1 ? false : true;
        }
        else if (filterTypeClient === "3") {
            //GENERAL PUBLIC
            _rfc = information_general_public.rfc_general_public;
            _id_tax_regime = information_general_public.id_tax_regime_general_public;
            _postal_code = information_general_public.cp_general_public;
            _id_periodicity = formInvoice.id_periodicity.value;
            _id_month = formInvoice.id_month.value;
            _name_receptor = information_general_public.name_general_public;
            _email_receptor = "pedropp_26@hotmail.com";
            _type_person_receptor = false;
            _date_1 = formInvoice.date_1;
            _date_2 = formInvoice.date_2;
        }
        let _list_test = [];
        let _list_payments = [];
        let _listInvoicesEgreesses = [];

        if (type_document === "I") {
            if (type_invoice_company === "1") {
                listExamsInvoice.forEach(_test => {
                    _list_test.push({
                        id_invoice_detail: _test.id_invoice_detail,
                        id_invoice: _test.id_invoice,
                        id_work_order: _test.id_work_order,
                        id_work_order_exam: _test.id_work_order_exam,
                        id_work_order_profile: _test.id_work_order_profile,
                        id_price: _test.id_price,
                        id_profile_price: _test.id_profile_price,
                        id_profile_curve_price: _test.id_profile_curve_price,
                        id_class_product_service: _test.id_class_product_service,
                        id_product_service: _test.id_product_service === "" ? null : _test.id_product_service,
                        id_unit: _test.id_unit,
                        product_code_service: _test.class_product_code_service,
                        identification_code: _test.identification_code,
                        code_unit: _test.code_unit,
                        name_unit: _test.name_unit,
                        nim: _test.nim,
                        description: _test.name + " " + _test.name_patient,
                        price: _test.price,
                        import: filterTypeClient == "3" ? _test?.import : 0,
                        price_urgent: _test.price_urgent,
                        total_discount: _test.total_discount,
                        price_tax: _test.price_tax,
                        retention: _test.retention,
                        total: filterTypeClient == "3" ? _test?.total : 0,
                        name_patient: _test.name_patient,
                        is_general_public: _test.is_general_public,
                        type_invoice_company: formInvoice.type_invoice_company,
                        is_deleted: _test.is_deleted,
                        description_type_public_general:  typeGlobal
                        
                    });
                });                
            }

            if (type_invoice_company === "2") {
                listExamsInvoice.forEach(_test => {
                    _list_test.push({
                        id_invoice_detail: _test.id_invoice_detail,
                        id_invoice: _test.id_invoice,
                        id_work_order: _test.id_work_order,
                        id_work_order_exam: _test.id_work_order_exam,
                        id_work_order_profile: _test.id_work_order_profile,
                        id_price: _test.id_price,
                        id_profile_price: _test.id_profile_price,
                        id_profile_curve_price: _test.id_profile_curve_price,
                        id_class_product_service: _test.id_class_product_service,
                        id_product_service: _test.id_product_service === "" ? null : _test.id_product_service,
                        id_unit: _test.id_unit,
                        product_code_service: _test.class_product_code_service,
                        identification_code: _test.identification_code,
                        code_unit: _test.code_unit,
                        name_unit: _test.name_unit,
                        nim: _test.nim,
                        description: _test.name + " " + _test.name_patient,
                        price: _test.price,
                        price_urgent: _test.price_urgent,
                        total_discount: _test.total_discount,
                        price_tax: _test.price_tax,
                        retention: _test.retention,
                        name_patient: _test.name_patient,
                        is_general_public: _test.is_general_public,
                        type_invoice_company: formInvoice.type_invoice_company,
                        priceGlobal: totalGlobal.toFixed(2),
                        price_discount_Global: dicountGlobal.toFixed(2),
                        code_unit_Global: "E48",
                        description_global: formInvoice.description,
                        is_deleted: _test.is_deleted
                    });
                });
            }

            if (type_invoice_company === "3") {
                listExamsInvoice.forEach(_test => {
                    _list_test.push({
                        id_invoice_detail: _test.id_invoice_detail,
                        id_invoice: _test.id_invoice,
                        id_work_order: _test.id_work_order,
                        id_work_order_exam: _test.id_work_order_exam,
                        id_work_order_profile: _test.id_work_order_profile,
                        id_price: _test.id_price,
                        id_profile_price: _test.id_profile_price,
                        id_profile_curve_price: _test.id_profile_curve_price,
                        id_class_product_service: _test.id_class_product_service,
                        id_product_service: _test.id_product_service === "" ? null : _test.id_product_service,
                        id_unit: _test.id_unit,
                        product_code_service: _test.class_product_code_service,
                        identification_code: _test.identification_code,
                        code_unit: _test.code_unit,
                        name_unit: _test.name_unit,
                        nim: _test.nim,
                        description: _test.name + " " + _test.name_patient,
                        price: _test.price,
                        price_urgent: _test.price_urgent,
                        total_discount: _test.total_discount,
                        price_tax: _test.price_tax,
                        retention: _test.retention,
                        name_patient: _test.name_patient,
                        is_general_public: _test.is_general_public,
                        type_invoice_company: formInvoice.type_invoice_company,
                        ListInvoicesExamsAndProfiles :examInvoice,
                        is_deleted: _test.is_deleted
                    });
                });                
            }           
        }
        else if (type_document === "P") {

            listInvoices.forEach(_inv => {
                _list_payments.push({
                    id_invoice: _inv.id_invoice,
                    id_pay_method: _inv.id_pay_method,
                    id_coin: _inv.id_coin,
                    uuid: _inv.uuid,
                    serie: _inv.serie,
                    folio: _inv.folio,
                    date: _inv.date,
                    subtotal: _inv.subtotal,
                    total_taxes: _inv.total_taxes,
                    total: _inv.total,
                    code_coin: _inv.code_coin,
                    version: "",
                    code_pay_method: _inv.code_pay_method,
                    type_change: 1,
                    name_type_method: _inv.name_type_method,
                    parciality_number: _inv.parciality_number,
                    previous_amount: _inv.previous_amount,
                    payment_amount: _inv.payment_amount,
                    outside_balance: _inv.outside_balance,
                });
            });
        }
        else if (type_document === "E")
        {
            _listInvoicesEgreesses = listInvoices;
        }

        let body = {
            id_branch: id_branch,
            id_invoice: formInvoice.id_invoice,
            id_patient: _id_patient,
            id_company: _id_company,
            id_company_business_name: _id_company_business_name,
            id_tax_regime_issuing: formInvoice.id_commercial_line.id_tax_regime,
            id_tax_regime_receptor: _id_tax_regime,
            id_commercial_line: formInvoice.id_commercial_line.value,
            id_use_cfdi: formInvoice.id_use_cfdi.value,
            id_pay_form: formInvoice.id_pay_form.value,
            id_coin: formInvoice.id_coin.value,
            id_periodicity: _id_periodicity,
            id_month: _id_month,
            id_type_releationship: type_document == "E" ? formInvoice.id_type_releationship.value : null,
            type_person_issuing: formInvoice.id_commercial_line.type_person,
            type_person_receptor: _type_person_receptor,
            type_document: formInvoice.type_document,
            type_invoice_company: formInvoice.type_invoice_company,
            year: new Date().getFullYear(),
            name_pay_method: formInvoice.id_method_pay.value,
            expedition_place: formInvoice.expedition_place,
            name_issuing: formInvoice.id_commercial_line.label,
            rfc_issuing: formInvoice.id_commercial_line.rfc,
            name_receptor: _name_receptor,
            rfc_receptor: _rfc,
            postal_code_receptor: _postal_code,
            postal_code_issuing: formInvoice.expedition_place,
            email_receptor: _email_receptor,
            payment_conditions: formInvoice.payment_conditions,
            type_change_coin: 1,
            is_general_public: formInvoice.is_general_public,
            additional_comments: formInvoice.comments_invoice,
            listInvoiceDetails: _list_test,
            ListInvoicesPayments: _list_payments,
            ListInvoicesEgreesses: _listInvoicesEgreesses,
            sendInvoiced: send_invoiced,
            ListInvoicesExamsAndProfiles: examInvoice,
            date_payment_C:  formInvoice.date_payment,
            description_global: formInvoice.description,
            currentSubtotal: subtotalInvoice,
            currentTotal: totalInvoice,
            currentTax: totalTaxInvoice,
            currentRetentions: totalRetentions,
            date_1: _date_1,
            date_2: _date_2
        };   

        console.log(body);
        
       const listTmp = await _handleRequest("POST", `Invoice/Create?is_preview=${send_invoiced}`, "Facturación", body);

        if (listTmp !== null) {
            createSweet("create", "info", "Exito!", "Se genero la factura con exito!");
            setDisabledSaveButton(false);
            handleClearValues();
        }
        else {
            setDisabledSaveButton(false);
        }
    }

    //#endregion

    //#region USE MEMO


    //complemento de pago

    useMemo(() => {

        if ( listInvoices.length > 0) {

            let _subtotal = 0;
            let _tax = 0;
            let _total = 0;
            let _discount = 0;


            listInvoices.forEach(_find => {
                console.log(_find);
                _subtotal += _find?.payment_amount;
                _tax += _find?.subtotal;
                _total += _find?.subtotal;
                _discount += _find?.discount;

            });

            let _subtotal_ = 0;
            let _tax_ = 0;
            let _total_ = _total;
            let _discount_ = 0;
            let _sumTotal_ = 0;


            _subtotal_ = parseFloat(
                ( Math.trunc(_subtotal * 10000) / 10000 )
                .toFixed(2)
            );
            _tax_ = parseFloat(
                ( Math.trunc((_total * 0.16) * 10000) / 10000 )
                .toFixed(2)
            );

            _discount_ = parseFloat(
                ( Math.trunc((_discount * 0.16) * 10000) / 10000 )
                .toFixed(2)
            );

            _sumTotal_ = parseFloat(
               ((_discount +  _discount_) )
                .toFixed(2)
            );

            setSubtotalC(_subtotal_);
            setTotalTaxC(_total_);
            setTotaC(_tax_);

            setSubtotalN(_discount)
            setTotalTaxN(_discount_)
            setTotaN(_sumTotal_)
        }


    }, [listInvoices])




    useMemo(() => {

        if (listExamsInvoice.length > 0) {
            console.log(listExamsInvoice);
            let _type_person_receptor = false;
            let _type_person_issuing = false;

            if (filterTypeClient === "1") {
                _type_person_receptor = informationPatient.type_person_patient === 1 ? false : true;
            }
            else if (filterTypeClient === "2") {
                _type_person_receptor = informationBusinessName.type_person_business_name === 1 ? false : true;
            }
            else if (filterTypeClient === "3") {
                _type_person_receptor = false;
            }

            _type_person_issuing = formInvoice.id_commercial_line.type_person;

            let decimals = 2;
            let icon = "";

            if (id_coin.value !== undefined) {
                decimals = id_coin.decimals;
                icon = id_coin.icon;
            }

            let _subtotal = 0;
            let _discount = 0;
            let _tax = 0;
            let _retentions = 0;
            let _total = 0;

            listExamsInvoice.forEach(obj => {

                if (!obj.is_deleted) {

                    if (filterTypeClient === "3") {
                        _subtotal += obj?.import;
                        _discount += obj.total_discount;
                        _tax += obj.price_tax;
                        _retentions += 0;
                        _total += obj?.total;
                    }
                    else {
                        let _validRetentions = obj.retention === undefined ? 0 : obj.retention;

                        _subtotal += (obj.price + obj.price_urgent);
                        _discount += obj.total_discount;
                        _tax += obj.price_tax;
                        _retentions += _validRetentions;
                    }
                }
            });

            let _subtotal_ = 0;
            let _discount_ = 0;
            let _tax_ = 0;
            let _retentions_ = 0;
            let _total_ = 0;

            if (filterTypeClient === "3") {
              
                _subtotal_ = parseFloat(
                    ( Math.trunc(_subtotal * 10000) / 10000 )
                    .toFixed(2)
                );
                _discount_ = parseFloat(
                    ( Math.trunc(_discount * 10000) / 10000 )
                    .toFixed(2)
                );
                _tax_ = parseFloat(
                    ( Math.trunc(_tax * 10000) / 10000 )
                    .toFixed(2)
                );
                _total_ = parseFloat(
                    ( Math.trunc(_total * 10000) / 10000 )
                    .toFixed(2)
                );
            }
            else if (type_invoice_company == "2") {
                 _subtotal_ = parseFloat(
                    ( Math.trunc(_subtotal * 10000) / 10000 )
                    .toFixed(2)
                );
                _discount_ = parseFloat(
                    ( Math.trunc(_discount * 10000) / 10000 )
                    .toFixed(2)
                );

                _tax_ = parseFloat(
                    ( Math.trunc((_subtotal_ * 0.16) * 10000) / 10000 )
                    .toFixed(2)
                );

                _total_ = parseFloat(
                    ( Math.trunc((_subtotal_ + _tax_) * 10000) / 10000 )
                    .toFixed(2)
                );
            }

            else {
                _total = Math.trunc((_subtotal + _tax) * 100) / 100;

                _total = Math.trunc((_total - _retentions) * 100) / 100;

                _subtotal_ = parseFloat(_subtotal.toFixed(decimals));
                _discount_ = parseFloat(_discount.toFixed(decimals));
                _tax_ = parseFloat(_tax.toFixed(decimals));
                _retentions_ = parseFloat(_retentions.toFixed(decimals));
                _total_ = parseFloat(_total.toFixed(decimals));
            }

            //complemeto de pago


     





            setSubtotalInvoice(_subtotal_);
            setTotalDiscountsInvoice(_discount_);
            setTotalTaxInvoice(_tax_);
            setTotalRetentions(_retentions_);
            setTotalInvoice(_total_);

            setIconCoin(icon);
        }

    }, [listExamsInvoice, id_coin,])
    //#endregion

    //#endregion

    //-------------------------------------------------------------
    //#region BUSINESS NAME

    //#region MODELS

    const [formBusinessName, handleInputChangeBusinessName, resetBusinessName, handleUpdateValuesBusinessName] = useForm({
        id_tax_regime_business_name: 0,
        business_name: "",
        rfc_business_name: "",
        cp_business_name: "",
        email_business_name: "",
        tax_regime_business_name: "",
        type_person_business_name: 2,
        code_tax_regime_business_name: ""
    });

    const {
        id_tax_regime_business_name, business_name, rfc_business_name, cp_business_name, email_business_name, tax_regime_business_name, type_person_business_name, code_tax_regime_business_name
    } = formBusinessName;

    //VALIDATIONS
    const [validationsBusinessName, setValidationsBusinessName] = useState({
        id_tax_regime_business_name_valid: false,
        business_name_valid: false,
        cp_business_name_valid: false,
        rfc_business_name_valid: false,
    });

    //#endregion

    //#region
    useEffect(() => {

        if (id_company_business_name > 0) {
            validationFormBusinessName();
        }

        if (id_tax_regime_business_name > 0 && type_person_business_name > 0) {
            handleGetListUseCfdi(id_tax_regime_business_name, type_person_business_name === 2 ? false : true);
        }

    }, [formBusinessName]);

    useEffect(() => {

        if (id_company_business_name > 0) {
            handleGetListNIm(null, formInvoice.id_company);
            setViewSearchClient(false);
        }

    }, [id_company_business_name]);

    //#endregion

    //#region METHODS
    const handleGetTaxRegime = async (version, physical_person = null, moral_person = null) => {
        let alternative_url = "";

        if (physical_person !== null) {
            alternative_url = `?physical_person=true`;
        }

        if (moral_person !== null) {

            alternative_url = `?moral_person=true`;
        }

        const listTmp = await _handleRequest("GET", `CfdiCatalog/ListTaxRegime/${version}${alternative_url}`);

        if (listTmp !== null && listTmp.length > 0) {

            let list = [];

            listTmp.forEach(tax => {
                list.push({
                    id_tax_regime: tax.id_tax_regime,
                    name: `(${tax.code}) ${tax.name}`
                });
            })

            setListTaxRegime(list);
        }
    }

    const validSelectBusinessName = () => {
        setListUseCfdi([]);

        handleUpdateValuesBusinessName({
            id_tax_regime_business_name: informationBusinessName.id_tax_regime_business_name,
            business_name: informationBusinessName.business_name,
            rfc_business_name: informationBusinessName.rfc_business_name,
            cp_business_name: informationBusinessName.cp_business_name,
            email_business_name: informationBusinessName.email_business_name,
            tax_regime_business_name: informationBusinessName.tax_regime_business_name,
            type_person_business_name: informationBusinessName.type_person_business_name,
            code_tax_regime_business_name: informationBusinessName.code_tax_regime_business_name
        });
    }

    const handleUpdateBusinessName = () => {
        handleUpdateValuesBusinessName({
            id_tax_regime_business_name: informationBusinessName.id_tax_regime_business_name,
            business_name: informationBusinessName.business_name,
            rfc_business_name: informationBusinessName.rfc_business_name,
            cp_business_name: informationBusinessName.cp_business_name,
            email_business_name: informationBusinessName.email_business_name,
            tax_regime_business_name: informationBusinessName.tax_regime_business_name,
            type_person_business_name: informationBusinessName.type_person_business_name,
            code_tax_regime_business_name: informationBusinessName.code_tax_regime_business_name
        });

        toggleModalBusinessName();
        setMethod("UpdateBussinessName");
        setModalTitle("Actualizar razón social");
        handleGetTaxRegime('4.0', null, true);
    }

    const saveUpdateBusinessName = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validationFormBusinessName()) {
            setLoading(false);
            return;
        }

        let body = {
            "id_company_business_name": formInvoice.id_company_business_name,
            "business_name": formBusinessName.business_name,
            "rfc": formBusinessName.rfc_business_name,
            "id_tax_regime": formBusinessName.id_tax_regime_business_name,
            "cp": formBusinessName.cp_business_name,
            "email": formBusinessName.email_business_name
        };

        const listTmp = await _handleRequest("PUT", "Company/UpdateBusinessName", "Actualizar razón social", body);

        if (listTmp !== null) {
            setLoading(false);

            setInformationBusinessName({
                id_tax_regime_business_name: formBusinessName.id_tax_regime_business_name,
                business_name: formBusinessName.business_name,
                rfc_business_name: formBusinessName.rfc_business_name,
                cp_business_name: formBusinessName.cp_business_name,
                email_business_name: formBusinessName.email_business_name,
                tax_regime_business_name: formBusinessName.tax_regime_business_name,
                type_person_business_name: formBusinessName.type_person_business_name,
                code_tax_regime_business_name: formBusinessName.code_tax_regime_business_name
            });

            handleUpdateBusinessName({
                id_tax_regime_business_name: 0,
                business_name: "",
                rfc_business_name: "",
                cp_business_name: "",
                email_business_name: "",
                tax_regime_business_name: "",
                type_person_business_name: 2,
                code_tax_regime_business_name: ""
            });

            createSweet("create", "info", "Exito!", "Razón social actualizada");
            toggleModalBusinessName();
            setMethod("");
        }
        else {
            setLoading(false);
        }
    }
    //#endregion

    //#endregion

    //-------------------------------------------------------------

    //#region PATIENT

    //#region PATIENT MODEL
    const [searchPatientState, setSearchPatientState] = useState([]);
    const [searchPatientExtra, setSearchPatientExtra] = useState([]);

    const [searchPatientValue, setSearchPatientValue] = useState({});

    const [formPatient, handleInputChangePatient, resetPatient, handleUpdateValuesPatient] = useForm({
        id_tax_regime_patient: 0,
        name_patient: "",
        business_name_patient: "",
        type_person_patient: 1,
        cp_patient: 0,
        tax_regime_patient: "",
        rfc_patient: "",
        code_tax_regime: "",
        email_patient: ""
    });

    const {
        id_tax_regime_patient, business_name_patient, name_patient, type_person_patient, cp_patient, tax_regime_patient, rfc_patient, code_tax_regime, email_patient
    } = formPatient;

    // VALIDATIONS
    const [validationsPatient, setValidationsPatient] = useState({
        id_tax_regime_patient_valid: false,
        rfc_patient_valid: false,
        cp_patient_valid: false,
        email_valid: false,
        business_name_patient_valid: false
    });

    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (id_patient > 0) {
            validationFormPatient();
        }

        if (id_tax_regime_patient > 0 && type_person_patient > 0) {
            handleGetListUseCfdi(id_tax_regime_patient, type_person_patient === 2 ? false : true);
        }
    }, [formPatient]);

    useEffect(() => {

        if (id_patient > 0) {
            handleGetListNIm(id_patient);
            setViewSearchPatient(false);
        }

    }, [id_patient]);


    //#endregion

    //#region PATIENTS METHOS
    const handleChangeTypePatient = (e) => {
        let _value = e.target.value;

        handleUpdateValuesPatient({
            ...formPatient,
            type_person_patient: _value,
            id_tax_regime_patient: 0
        });

        handleGetTaxRegime("4.0", parseInt(_value) === 1 ? true : null, parseInt(_value) === 2 ? true : null);
    }

    const handleChangePayMethodPatient = () => {

        let _findPayMethod = listPayMethods.find(x => x.value === "PUE");

        if (_findPayMethod !== undefined) {

            handleUpdateValues({

                ...formInvoice,
                id_patient: 0,
                id_company: 0,
                id_company_business_name: 0,
                id_coin: 0,
                id_use_cfdi: 0,
                id_periodicity: 0,
                id_month: 0,
                id_type_releationship: ExportInvoiceDefaultValues.defaultTypeReleationship,
                id_method_pay: _findPayMethod,
                id_pay_form: 0,
                payment_conditions: "",
                is_general_public: false,
                comments_invoice: "",
                information_general_public: {
                    name_general_public: "PUBLICO EN GENERAL",
                    rfc_general_public: "XAXX010101000",
                    cp_general_public: "01000",
                    id_tax_regime_general_public: 0,
                    code_tax_regime_general_public: "",
                    tax_regime_general_public: ""
                }
            });
        }
    }

    const validSelectPatient = () => {
        setListUseCfdi([]);

        handleUpdateValuesPatient({
            id_tax_regime_patient: informationPatient.id_tax_regime_patient,
            name_patient: informationPatient.name_patient,
            type_person_patient: informationPatient.type_person_patient,
            cp_patient: informationPatient.cp_patient,
            tax_regime_patient: informationPatient.tax_regime_patient,
            rfc_patient: informationPatient.rfc_patient,
            business_name_patient: informationPatient.business_name,
        });
    }

    const handleUpdatePatient = () => {
        handleUpdateValuesPatient({
            "id_tax_regime_patient": informationPatient.id_tax_regime_patient,
            "name_patient": informationPatient.name_patient,
            "type_person_patient": informationPatient.type_person_patient,
            "cp_patient": informationPatient.cp_patient,
            "tax_regime_patient": informationPatient.tax_regime_patient,
            "rfc_patient": informationPatient.rfc_patient,
            "code_tax_regime": informationPatient.code_tax_regime,
            "email_patient": informationPatient.email,
            "business_name_patient": informationPatient.business_name
        });

        toggleModalPatient();
        setModalTitle("Actualizar datos fiscales");
        setMethod("updateBusinessNamePatient");
        handleGetTaxRegime("4.0", informationPatient.type_person_patient === 1 ? true : null, informationPatient.type_person_patient === 2 ? true : null);
    }

    const saveUpdatePatient = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validationFormPatient()) {
            setLoading(false);
            return;
        }

       let _valid =  (id_patient_r !== undefined && id_work_order_r !== undefined) ?  id_patient_r : formInvoice.id_patient

        let body = {
            id_patient: _valid,
            id_tax_regime: formPatient.id_tax_regime_patient,
            type_person: formPatient.type_person_patient,
            rfc: formPatient.rfc_patient,
            cp: formPatient.cp_patient,
            email: formPatient.email_patient,
            business_name: formPatient.business_name_patient
        };

        const listTmp = await _handleRequest("PUT", "Patient/UpdateTaxInformation", "Actualizar paciente", body);

        if (listTmp !== null) {
            setLoading(false);

            setInformationPatient({
                id_tax_regime_patient: listTmp.id_tax_regime,
                id_use_cfdi: listTmp.id_use_cfdi === null ? 0 : listTmp.id_use_cfdi,
                name_patient: listTmp.full_name,
                business_name: listTmp.business_name,
                type_person_patient: listTmp.type_person,
                cp_patient: listTmp.postal_code,
                rfc_patient: listTmp.rfc,
                email: listTmp.email,
                code_tax_regime: listTmp.code_tax_regime,
                tax_regime_patient: listTmp.name_tax_regime,
                code_use_cfdi: listTmp.code_use_cfdi,
                name_use_cfdi: listTmp.name_use_cfdi,
            });

            createSweet("create", "info", "Exito!", "Paciente actualizado");
            toggleModalPatient();
            setMethod("");
        }
        else {
            setLoading(false);
        }
    }



    const handleSearchExtraP = () => {
        toggleModalExtraPatients();
        setModalTitle("Agregar a otros pacientes");

        setSearchPatientExtra([]);
    }

    const handleChangeExtraPatient = async (e) => {
        console.log(e);
        
        setSearchPatientValue(e);

        let _value = e.target.value;
        console.log(_value);

        let listWOrdersTemp = listTempExtraPatients;
        console.log(listWOrdersTemp);

        if (listWOrdersTemp.find(x => x.id_work_order == _value)) {
            createSweet("warning", "warning", "La admisión seleccionada ya se encuentra seleccionada", "");
            return;
        }

        const id_coin = formInvoice.id_coin?.value ?? id_coin;
        const type_person = id_commercial_line?.type_person ?? true;

        const listTmp = await _handleRequest("GET", `Invoice/GetDetailWorkOrderInvoice/${_value}/ADMISION/${id_coin}/${type_person}`, "Detalle admisión");
        console.log(listTmp);
        console.log("que llevas");


        if (listTmp !== null && listTmp.length > 0) {
            listTmp.forEach(wOrders => {
                let findWorder = listWOrdersTemp.find(x => x.id_work_order === wOrders.id_work_order);

                if (findWorder === undefined) {
                    listWOrdersTemp.push({
                        id_work_order: wOrders.id_work_order,
                        nim: wOrders.nim,
                        name_patient: wOrders.name_patient,
                        list_test: [
                            {
                                id_invoice_detail: null,
                                id_invoice: null,
                                id_work_order: wOrders.id_work_order,
                                id_work_order_exam: wOrders.id_work_order_exam,
                                id_work_order_profile: wOrders.id_work_order_profile,
                                id_exam: wOrders.id_exam,
                                id_profile: wOrders.id_profile,
                                id_price: wOrders.id_price,
                                id_profile_price: wOrders.id_profile_price,
                                id_profile_curve_price: wOrders.id_profile_curve_price,
                                id_class_product_service: wOrders.id_class_product_service,
                                id_product_code_service: wOrders.id_product_code_service == null ? "" : wOrders.id_product_code_service ,
                                id_unit: wOrders.id_unit,
                                id_unit_code: wOrders.id_unit_code,
                                class_product_code_service: wOrders.class_product_code_service,  
                                product_code_service: wOrders.product_code_service,
                                identification_code: wOrders.identification_code,
                                code_unit: wOrders.code_unit,
                                name_unit: wOrders.name_unit,                                
                                nim: wOrders.nim,
                                name: wOrders.name,
                                price: wOrders.price,
                                price_urgent: wOrders.price_urgent,
                                total_discount: wOrders.total_discount,
                                price_tax: wOrders.price_tax,
                                product_code_service: wOrders.product_code_service,
                                code_unit: wOrders.code_unit,
                                name_unit: wOrders.name_unit,
                                name_patient:  wOrders.name_patient,
                                listTest: [],
                                is_general_public: false,
                                is_deleted: false
                            }
                        ]
                    });
                }
                else if (findWorder !== undefined) {
                    let listTestTmp = findWorder.list_test;

                    console.log("pasas");

                    listTestTmp.push({
                        id_work_order: wOrders.id_work_order,
                        id_work_order_exam: wOrders.id_work_order_exam,
                        id_work_order_profile: wOrders.id_work_order_profile,
                        id_price: wOrders.id_price,
                        id_profile_price: wOrders.id_profile_price,
                        id_profile_curve_price: wOrders.id_profile_curve_price,
                        id_product_code_service: wOrders.id_product_code_service,
                        id_unit_code: wOrders.id_unit_code,
                        nim: wOrders.nim,
                        name: wOrders.name,
                        price: wOrders.price,
                        price_urgent: wOrders.price_urgent,
                        total_discount: wOrders.total_discount,
                        price_tax: wOrders.price_tax,
                        product_code_service: wOrders.product_code_service,
                        code_unit: wOrders.code_unit,
                        name_unit: wOrders.name_unit,
                        name_patient: wOrders.name_patient,
                        listTest: [],
                        is_general_public: false,
                        class_product_code_service: wOrders.class_product_code_service,
  

                    });
                }
            });


            setListTempExtraPatients(listWOrdersTemp);
        }

        setSearchPatientExtra([]);
    }

    const handleAddExtraPatients = () => {
        let _listTest = listExamsInvoice;

        setLoadingExtraPatients(true);

        if (listTempExtraPatients.length > 0) {
            listTempExtraPatients.forEach(_extra => {
                _extra.list_test.forEach(_test => {
                    _listTest.push(_test);
                });
            });

            setListExamsInvoice([..._listTest]);
        }

        setTimeout(() => {
            setLoadingExtraPatients(false);
            setListTempExtraPatients([]);
            toggleModalExtraPatients();
        }, 2000);
    }
    //#endregion

    //#endregion

    //-------------------------------------------------------------

    //#region GENERAL PUBLIC

    //#region MODELS
    const [validDateFrom, setValidDateFrom] = useState("");
    const [validDateTo, setValidDateTo] = useState("");

    const [formGenPub, handleInputChangeGenPub, resetGenPub, handleUpdateValuesGenPub, handleSelectValuesGenPub] = useForm({
        date_periodicity: "",
        week_from_periocity: "",
        week_to_periocity: "",
        id_branchI : 0,
        idFilterPayMethod: 1
    });

    const {
        date_periodicity, week_from_periocity, week_to_periocity, id_branchI, idFilterPayMethod
    } = formGenPub;

  

    
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {

        if (information_general_public.id_tax_regime_general_public > 0) {
            handleGetListUseCfdi(information_general_public.id_tax_regime_general_public, true);
        }

    }, [information_general_public]);

    useEffect(() => {

        if (id_periodicity.value !== undefined && id_month.value !== undefined) {

            let _today = "";
            let _lastDate = "";
            let _lasDay = "";

            if (id_periodicity.code === "04") {
                _today = new Date().getFullYear() + "-" + id_month.code + "-01";
                console.log(_today);

                _lastDate = new Date(new Date().getFullYear(), id_month.code, 0);

                _lasDay = _lastDate.getFullYear() + "-" + id_month.code + "-" + _lastDate.getDate();

                setValidDateFrom(_today);

                setValidDateTo(_lasDay);

                handleUpdateValuesGenPub({
                    date_periodicity: "",
                    week_from_periocity: _today,
                    week_to_periocity: _lasDay,
                    id_branchI : 0,
                    idFilterPayMethod: 1
                });
            }
            else if (id_periodicity.code === "05") {
                switch (id_month.code) {
                    case "13":

                    console.log("que lleva");

                        _today = new Date().getFullYear() + "-01-01";

                        _lastDate = new Date(new Date().getFullYear(), "02", 0);

                        _lasDay = _lastDate.getFullYear() + "-02-" + _lastDate.getDate();
                        break;
                    case "14":

                        _today = new Date().getFullYear() + "-03-01";

                        _lastDate = new Date(new Date().getFullYear(), "04", 0);

                        _lasDay = _lastDate.getFullYear() + "-04-" + _lastDate.getDate();
                        break;
                    case "15":

                        _today = new Date().getFullYear() + "-05-01";

                        _lastDate = new Date(new Date().getFullYear(), "06", 0);

                        _lasDay = _lastDate.getFullYear() + "-06-" + _lastDate.getDate();
                        break;
                    case "16":

                        _today = new Date().getFullYear() + "-07-01";

                        _lastDate = new Date(new Date().getFullYear(), "08", 0);

                        _lasDay = _lastDate.getFullYear() + "-08-" + _lastDate.getDate();
                        break;
                    case "17":

                        _today = new Date().getFullYear() + "-09-01";

                        _lastDate = new Date(new Date().getFullYear(), "10", 0);

                        _lasDay = _lastDate.getFullYear() + "-10-" + _lastDate.getDate();
                        break;
                    case "18":

                        _today = new Date().getFullYear() + "-11-01";

                        _lastDate = new Date(new Date().getFullYear(), "12", 0);

                        _lasDay = _lastDate.getFullYear() + "-12-" + _lastDate.getDate();
                        break;

                    default:
                        break;
                }

                setValidDateFrom(_today);

                setValidDateTo(_lasDay);

                handleUpdateValuesGenPub({
                    date_periodicity: "",
                    week_from_periocity: _today,
                    week_to_periocity: _lasDay,
                    id_branchI : 0,
                    idFilterPayMethod: 1
                });
            }
            else {
                _today = new Date().getFullYear() + "-" + id_month.code + "-01";

                _lastDate = new Date(new Date().getFullYear(), id_month.code, 0);

                _lasDay = _lastDate.getFullYear() + "-" + id_month.code + "-" + _lastDate.getDate();

                setValidDateFrom(_today);
                setValidDateTo(_lasDay);

                
                handleUpdateValuesGenPub({
                    date_periodicity: "",
                    week_from_periocity: "",
                    week_to_periocity: "",
                    id_branchI : 0,
                    idFilterPayMethod: 1
                });
            }
        }

    }, [id_month]);


    //#endregion

    //#region GENERAL PUBLIC METHODS
    const handleChangePayMethodGeneralPublic = () => {

        let _findPayMethod = listPayMethods.find(x => x.value === "PUE");

        if (_findPayMethod !== undefined) {

            handleUpdateValues({
                ...formInvoice,
                id_patient: 0,
                id_company: 0,
                id_company_business_name: 0,
                id_coin: 0,
                id_use_cfdi: 0,
                id_periodicity: 0,
                id_month: 0,
                id_type_releationship: ExportInvoiceDefaultValues.defaultTypeReleationship,
                id_method_pay: _findPayMethod,
                id_pay_form: 0,
                payment_conditions: "",
                is_general_public: true
            });
        }
    }

    const handleFilterTaxRegimeGeneralPublic = async (_periodicity) => {

        if (_periodicity.value !== undefined) {

            let _tmpInformationGeneralPublic = information_general_public;

            let alternative_url = "?physical_person=true";

            const listTmp = await _handleRequest("GET", `CfdiCatalog/ListTaxRegime/4.0${alternative_url}`);

            if (listTmp !== null && listTmp.length > 0) {

                if (_periodicity.code !== "05") {
                    let _findRegime = listTmp.find(x => x.code === "616");

                    if (_findRegime !== undefined) {


                        _tmpInformationGeneralPublic = {
                            ...information_general_public,
                            id_tax_regime_general_public: _findRegime.id_tax_regime,
                            tax_regime_general_public: `(${_findRegime.code}) ${_findRegime.name}`,
                            code_tax_regime_general_public: _findRegime.code
                        };
                    }
                }
                else {
                    let _findRegime = listTmp.find(x => x.code === "621");

                    if (_findRegime !== undefined) {

                        _tmpInformationGeneralPublic = {
                            ...information_general_public,
                            id_tax_regime_general_public: _findRegime.id_tax_regime,
                            tax_regime_general_public: `(${_findRegime.code}) ${_findRegime.name}`,
                            code_tax_regime_general_public: _findRegime.code
                        };
                    }
                }
            }

            handleUpdateValues({
                ...formInvoice,
                id_periodicity: _periodicity,
                information_general_public: _tmpInformationGeneralPublic,
                id_use_cfdi: 0,
                id_month: 0
            });
        }
    }


    

    const handleChangeMonthGenPub = (e) => {
        console.log(e.target.value);

        let _validFrom = new Date(validDateFrom);
        let _validTo = new Date(validDateTo);

        if (e.target.name === "date_periodicity") {
            handleInputChangeGenPub(e);
            setDataFech(e.target.value)
        }
        else {
            if (id_periodicity.value !== undefined && (id_periodicity.code === "02" || id_periodicity.code === "03")) {
                let _day = new Date(e.target.value);

                console.log(_day);
               
                let _newDate = _day.setDate(_day.getDate() + (id_periodicity.code === "02" ? 7 : 15));
                let _nextDate = new Date(_newDate);
                console.log(_newDate);
                console.log(_nextDate);

                if (_validFrom <= _nextDate && _validTo >= _nextDate) {

                    handleUpdateValuesGenPub({
                        ...formGenPub,
                        week_from_periocity: e.target.value,
                        week_to_periocity: _nextDate.getFullYear() + "-" + ((_nextDate.getMonth() + 1) < 10 ? "0" + (_nextDate.getMonth() + 1) : (_nextDate.getMonth() + 1)) + "-" + (_nextDate.getDate() < 10 ? "0" + _nextDate.getDate() : _nextDate.getDate())
                    });
                }
                else {
                    handleUpdateValuesGenPub({
                        ...formGenPub,
                        week_from_periocity: e.target.value,
                        week_to_periocity: ""
                    });
                }
            }
        }
    }


    const handleFilterSearchNims = () => {
        handleGetListGeneralPub(id_periodicity.code, id_month.code, (id_periodicity.code === "01" ? date_periodicity : week_from_periocity), (id_periodicity.code === "01" ? date_periodicity : week_to_periocity), ( id_branchI === null || id_branchI.value === undefined ? undefined : id_branchI.value ), (idFilterPayMethod === null || idFilterPayMethod.value === undefined ? null :idFilterPayMethod.value));
    }

    const handleReturnFilter = () => {
        setViewFilterNims(!viewFilterNims);
    }
    //#endregion

    //INVOICE DASHBOARD
    const handleDashboard = () => {
        history.replace(`${process.env.PUBLIC_URL}/dashboard/default`);
    }

    //#endregion

    //#region VALIDATION
    const validationFormBusinessName = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formBusinessName.business_name !== "") {
            let tmp_business_name = formBusinessName.business_name.toUpperCase();

            if (tmp_business_name.includes("S.A") || tmp_business_name.includes("SA.")) {
                newValidations = {
                    ...newValidations,
                    business_name_valid: true
                };

                statusValidation = true;
            }
            else if (tmp_business_name.includes("S.A.S") || tmp_business_name.includes("SAS") || tmp_business_name.includes("SAS.")) {
                newValidations = {
                    ...newValidations,
                    business_name_valid: true
                };

                statusValidation = true;
            }
            else if (tmp_business_name.includes("S.A. DE C.V.") || tmp_business_name.includes("SA DE CV") || tmp_business_name.includes("SA. DE CV.")) {
                newValidations = {
                    ...newValidations,
                    business_name_valid: true
                };

                statusValidation = true;
            }
            else if (tmp_business_name.includes("S. DE R.L.") || tmp_business_name.includes("S DE RL") || tmp_business_name.includes("S. DE RL.")) {
                newValidations = {
                    ...newValidations,
                    business_name_valid: true
                };

                statusValidation = true;
            }
            else if (tmp_business_name.includes("S. DE R.L. DE C.V") || tmp_business_name.includes("S DE RL DE CV") || tmp_business_name.includes("S. DE RL. DE CV.")) {
                newValidations = {
                    ...newValidations,
                    business_name_valid: true
                };

                statusValidation = true;
            }
            else if (tmp_business_name.includes("S.A.P.I. DE C.V.") || tmp_business_name.includes("SAPI DE CV") || tmp_business_name.includes("SAPI. DE CV.")) {
                newValidations = {
                    ...newValidations,
                    business_name_valid: true
                };

                statusValidation = true;
            }
            else if (tmp_business_name.includes("S.C.") || tmp_business_name.includes("SC.")) {
                newValidations = {
                    ...newValidations,
                    business_name_valid: true
                };

                statusValidation = true;
            }
        }
        else if (formBusinessName.business_name === "") {
            newValidations = {
                ...newValidations,
                business_name_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                business_name_valid: false
            };
        }

        if (formBusinessName.id_tax_regime_business_name === 0) {
            newValidations = {
                ...newValidations,
                id_tax_regime_business_name_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_tax_regime_business_name_valid: false
            }
        }

        if (formBusinessName.cp_business_name === "") {
            newValidations = {
                ...newValidations,
                cp_business_name_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                cp_business_name_valid: false
            };
        }

        setValidationsBusinessName(newValidations);

        return statusValidation;
    }

    const validationFormPatient = () => {
        let statusValidation = false;
        let newValidations = {};
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (formPatient.type_person_patient !== "" && (parseInt(formPatient.type_person_patient) === 1 && (formPatient.rfc_patient !== "" && formPatient.rfc_patient.length === 13)) || (parseInt(formPatient.type_person_patient) === 2 && (formPatient.rfc_patient !== "" && formPatient.rfc_patient.length === 12))) {
            newValidations = {
                ...newValidations,
                rfc_patient_valid: false
            };
        }
        else {
            newValidations = {
                ...newValidations,
                rfc_patient_valid: true
            };

            statusValidation = true;
        }

        if (formPatient.id_tax_regime_patient === 0) {
            newValidations = {
                ...newValidations,
                id_tax_regime_patient_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_tax_regime_patient_valid: false
            };
        }

        if (formPatient.cp_patient === "" || formPatient.cp_patient === 0) {
            newValidations = {
                ...newValidations,
                cp_patient_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                cp_patient_valid: false
            };
        }

        if (!pattern.test(formPatient.email_patient)) {
            newValidations = {
                ...newValidations,
                email_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                email_valid: false
            };
        }

        if (formPatient.business_name_patient.length === 0) {
            newValidations = {
                ...newValidations,
                business_name_patient_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                business_name_patient_valid: false
            };
        }

        setValidationsPatient(newValidations);

        return statusValidation;
    }

    const validationInvoice = () => {
        let statusValidationI = false;
        let validationsI = {};

        if (formInvoice.id_patient <= 0 && filterTypeClient === "1") {
            validationsI = {
                ...validationsI,
                _id_patient_valid: true
            };

            statusValidationI = true;
        }
        else {
            validationsI = {
                ...validationsI,
                _id_patient_valid: false
            };
        }

        if (formInvoice.id_company <= 0 && filterTypeClient === "2") {
            validationsI = {
                ...validationsI,
                _id_company_valid: true
            };

            statusValidationI = true;
        }
        else {
            validationsI = {
                ...validationsI,
                _id_company_valid: false
            };
        }

        if (formInvoice.id_company_business_name <= 0 && filterTypeClient === "2") {
            validationsI = {
                ...validationsI,
                _id_company_business_name_valid: true
            };

            statusValidationI = true;
        }
        else {
            validationsI = {
                ...validationsI,
                _id_company_business_name_valid: false
            };
        }

        if (formInvoice.id_commercial_line.value === undefined) {
            validationsI = {
                ...validationsI,
                _id_commercial_line_valid: true
            };

            statusValidationI = true;
        }
        else {
            validationsI = {
                ...validationsI,
                _id_commercial_line_valid: false
            };
        }

        if (formInvoice.id_use_cfdi.value === undefined) {
            validationsI = {
                ...validationsI,
                _id_use_cfdi_valid: true
            };

            statusValidationI = true;
        }
        else {
            validationsI = {
                ...validationsI,
                _id_use_cfdi_valid: false
            }
        }

        if (formInvoice.expedition_place === "" || formInvoice.expedition_place === "0" || formInvoice.expedition_place <= 0) {
            validationsI = {
                ...validationsI,
                _expedition_place_valid: true
            };

            statusValidationI = true;
        }
        else {
            validationsI = {
                ...validationsI,
                _expedition_place_valid: false
            };
        }

        if (formInvoice.type_document !== "P" && formInvoice.id_method_pay.value === undefined) {
            validationsI = {
                ...validationsI,
                _id_method_pay_valid: true
            };

            statusValidationI = true;
        }
        else {
            validationsI = {
                ...validationsI,
                _id_method_pay_valid: false
            };
        }

        if (formInvoice.id_pay_form.value === undefined) {
            validationsI = {
                ...validationsI,
                _id_pay_form_valid: true
            };

            statusValidationI = true;
        }
        else {
            validationsI = {
                ...validationsI,
                _id_pay_form_valid: false
            };
        }

        if (formInvoice.id_coin.value === undefined) {
            validationsI = {
                ...validationsI,
                _id_coin_valid: true
            };

            statusValidationI = true;
        }
        else {
            validationsI = {
                ...validationsI,
                _id_coin_valid: false
            };
        }

        if (formInvoice.type_document !== "P" && formInvoice.tax_iva.value === undefined) {
            validationsI = {
                ...validationsI,
                _tax_iva_value: true
            };

            statusValidationI = true;
        }
        else {
            validationsI = {
                ...validationsI,
                _tax_iva_value: false
            };
        }

        if (formInvoice.type_document === "I" && listExamsInvoice.length <= 0) {
            validationsI = {
                ...validationsI,
                _list_exams_invoice_valid: true
            };

            statusValidationI = true;
        }
        else {
            validationsI = {
                ...validationsI,
                _list_exams_invoice_valid: false
            };
        }

        if (is_general_public) {
            if (formInvoice.id_periodicity.value === undefined) {
                validationsI = {
                    ...validationsI,
                    _id_periodicity_valid: true
                };

                statusValidationI = true;
            }
            else {
                validationsI = {
                    ...validationsI,
                    _id_periodicity_valid: false
                };
            }

            if (formInvoice.id_month.value === undefined) {
                validationsI = {
                    ...validationsI,
                    _id_month_valid: true
                };

                statusValidationI = true;
            }
            else {
                validationsI = {
                    ...validationsI,
                    _id_month_valid: false
                };
            }
        }

        setValidationsInvoice(validationsI);

        return statusValidationI;

    }
    //#endregion

    //GLOBAL REQUEST
    const _handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
         


            return response.data;

        }
        else if (response.code === 401) {
            validarSesion(history, response.code, _handleRequest);
        }
        
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }

    const format_trunc_number = (_quantity, _decimals) => {
        const _base = 10;
        let _number_decimals = Math.pow(_base, _decimals);

        let _format_number = Math.trunc(_quantity * _number_decimals) / _number_decimals;

        return _format_number;
    }

    return {
        //GLOBAL VARIABLES
        loading, loadingPreview, loadingFilterWorkOrders,

        //MODALS AND MODALS METHODS
        modalBusinessName, modalPatient, modalPreviewInvoice, modalExtraPatients, modalListEgress, modalTitle, method,
        toggleModalBusinessName, toggleModalPatient, toggleModalPreviewInvoice, toggleModalExtraPatients, toggleModalListEgress,

        //#region INVOICE RETURN

        //INVOICE -> MODELS
        formInvoice,
        id_patient, id_company, id_company_business_name, id_commercial_line, id_pay_form, id_periodicity,
        id_method_pay, id_coin, id_use_cfdi, id_month, id_type_releationship,
        type_document, expedition_place, tax_iva, payment_conditions, comments_invoice, is_general_public, information_general_public,
        viewSearchPatient, viewSearchClient, viewFilterNims,
        subtotalInvoice, totalDiscountsInvoice, totalTaxInvoice, totalRetentions, totalInvoice,
        iconCoin, disabledSaveButton,
        filterWorkOrder, filterInvoices,

        filterTypeClient,
        listPayForms, listPayMethods, listCoins, listCommercialLines, listTaxRegime, listUseCfdi,
        listExamsInvoice, listNims, listPeriodicities, listCfdiMonths, listTaxes,
        listInvoices, listFilterPayMethods, listTypeRelationship,

        typeMethodDiscount, amountDiscount, isGlobalDiscount,

        //INVOICE -> METHODS
        handleInputChange, handleUpdateValues, handleSelectValues,
        handleChangeTypeInvoice, handleChangeTypeClient,
        handleChangeTypeMethods, handleChangeNim,
        setViewSearchPatient, setViewSearchClient,
        handleChangePeriodicity,
        handleChangeMonthGenPub, handleReturnFilter,
        handlePreviewInvoice, saveInvoiceHelen,
        handleChangeInvoice, handleChangePaymentAmount,
        handleChangeCommercialLines, handleSelectInvoice,
        handleChangeTypeDiscount, handleChangeAmountDiscount,
        handleChangeGlobalDiscount, handleChangeConcept,
        //#endregion

        //#region BUSINESS NAME RETURN

        //BUSINESS NAME -> VALIDATIONS
        validationsInvoice,


        //BUSINESS NAME -> MODELS
        id_tax_regime_business_name, business_name, rfc_business_name, cp_business_name,
        email_business_name, tax_regime_business_name, type_person_business_name, informationBusinessName, setInformationBusinessName,

        //BUSINESS NAME -> METHODS
        handleInputChangeBusinessName, handleUpdateBusinessName, saveUpdateBusinessName, validSelectBusinessName,

        //BUSINESS NAME -> VALIDATIONS
        validationsBusinessName,

        //#endregion

        //#region PATIENT RETURN

        //PATIENT -> MODELS
        searchPatientState,
        id_tax_regime_patient, type_person_patient, cp_patient, tax_regime_patient, rfc_patient, email_patient, business_name_patient,
        informationPatient, setInformationPatient, searchPatientExtra, searchPatientValue, listTempExtraPatients,
        loadingExtraPatients,

        //PATIENT -> METHODS
        handleInputChangePatient, handleUpdatePatient, saveUpdatePatient, validSelectPatient,
        handleChangePayMethodPatient, handleSearchExtraP, handleChangeExtraPatient, handleAddExtraPatients,
        handleChangeTypePatient,

        //PATIENT -> VALIDATIONS
        validationsPatient,

        //#endregion

        //#region GENERAL PUBLIC RETURN

        //GENERAL PUBLIC -> MODELS
        handleInputChangeGenPub, date_periodicity, week_from_periocity, week_to_periocity, idFilterPayMethod,
        validDateFrom, validDateTo,

        //GENERAL PUBLIC -> METHODS
        handleChangePayMethodGeneralPublic, handleFilterSearchNims,

        //#endregion

        //#region DASHBOARD INVOICE
        id_work_order_r, id_patient_r, handleDashboard,_id_company_r,
        //#endregion DASHBOARD INVOICE
        number_format,
        id_branchI,branch,handleSelectValuesGenPub,

        //FILTER RANGE COMPANY
        setId_company_filter,handleCloseFilterCompany,view_filter_company,handleViewCompany,

        dateFrom,dateTo,handleInputChangeFech,removeInvoice,

        //FILTER INVOICE COMPANY
        type_invoice_company,handleChangeInvoiceCompany,

        //GROUP BY EXAM AND PROFILE
        dicountGlobal,totalGlobal,description,examInvoice,priceInvoice,
        request_id_invoice,

        //type description
        handleChangeDescription,is_general_public_type_description,
        typeGlobal,

        date_payment,subtotalC,totalTaxC,totalC,
        //nota de credito
        subtotalN,totalTaxN,totalN,codeGlobal,
        handleChangeProductCode


    }
}
