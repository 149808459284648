import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { TreeView } from '../../constant';
import { ConfigServer } from "../../data/config";
import { ConnectedOverlayScrollHandler } from 'primereact/utils';
import { set } from 'lodash';

export const useUser = () => {

    //GLOBAL VARIABLES
    const history = useHistory();
    const numberItems = 10;
    //SHOW DISPLAY MESSAGE
    const [createSweet] = useSweetAlert();
    //END SHOW DISPLAT MESSAGE

    //#region MODALS
    const [modalTitle, setModalTitle] = useState(false);
    const [modalUser, setModalUser] = useState(false);
    const [modalCard, setModalCard] = useState(false);
    const [methodUser, setMethodUser] = useState("");


    const toggleUser = () => {
        setModalUser(!modalUser);
    }

    const toggleCard = () => {
        setModalCard(!modalCard);
    }

    const [modalTitleSection, setModalTitleSection] = useState(false)
    const [modalSection, setModalSection] = useState(false);
    const [typeModal, setTypeModal] = useState("typeSection")
    const [typeCard, setTypeCard] = useState("typeCard")

    const [modalTitleCard, setModalTitleCard] = useState(false)

    const toggleSection = () => {
        setModalSection(!modalSection)
    }

    //#endregion MODALS

    //#region USERS
    //#region USERS MODEL
    const [listUsers, setListUsers] = useState([]);
    const [listUsersSearch, setListUsersSearch] = useState([]);
    const [userId, setUserId] = useState(0);
    const [listRol, setListRol] = useState([]);

    const [loading, setLoading] = useState(false);

    const [formUser, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        name_user: "",
        listRoles: [],
        listSection: [],
        card_code: "",
        id_departaments: [],
        listSections: [],
        checkSection: false
    });

    const { name_user, listRoles, listSection, card_code, id_departaments, listSections, checkSection } = formUser;
    //#endregion USERS MODEL

    //#region USERS VALIDATIONS
    const [validationsUser, setValidationsUser] = useState({
        listRoles_valid: false
    });
    //#endregion USERS VALIDATIONS        

    //#region USERS METHOD

    const handleGetUsersRoles = useCallback(async () => {
        console.log("entro");
        let listTmp = await handleRequest("GET", "Users", "Usuarios");
        console.log(listTmp);
        ////console.log(listTmp);

        if (listTmp !== null && listTmp.length > 0) {
            console.log("entro metodo");


            let list = [];

            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            // let name_roles;
            // let array_roles = [];


            listTmp.forEach((obj, index) => {
                let posicion = index + 1;
                let visibleTable = false;

                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }


                let array_roles = [];
                let name_roles;

                if (obj.roles !== undefined && obj.roles !== null) {
                    name_roles = [];

                    obj.roles.forEach(r => {

                        name_roles.push(
                            [r]
                        );
                        array_roles.push(r);
                    })
                }
                else {
                    name_roles = "Sin roles asignados"
                }

                let array_IDroles = [];
                let id_roles;

                if (obj.ids_roles !== undefined && obj.ids_roles !== null) {
                    id_roles = [];

                    obj.ids_roles.forEach(element => {

                        id_roles.push(
                            [element]
                        );
                        array_IDroles.push(element)
                    });
                }
                else {
                    id_roles = 0
                }

                list.push({
                    id_user: obj.id_user,
                    name: obj.name + " " + obj.paternal_surname + " " + obj.maternal_surname,
                    name_user: obj.name,
                    paternal_surname: obj.paternal_surname,
                    maternal_surname: obj.maternal_surname,
                    username: obj.username,
                    id_helen_users: obj.id_helen_users,
                    id_external: obj.id_external,
                    job: obj.job,
                    card_code: obj.card_code,
                    areas: obj.areas,
                    array_roles: array_roles,
                    name_roles: name_roles,
                    ids_roles: obj.ids_roles,
                    ids_sections: obj.ids_sections,
                    sections: obj.sections,
                    visibleTable: visibleTable
                });
            });
            setListUsers(list);
            setListUsersSearch(list);
        }

    }, [history]);

    const handleGetRoles = async () => {

        let listTmp = await handleRequest("GET", "Roles/List", "Roles");

        if (listTmp !== null && listTmp.length > 0) {

            let list = [];
            listTmp.forEach(obj => {
                list.push({
                    value: obj.id_role,
                    label: obj.name
                });
            });

            setListRol(list);
        }
    }

    const handleUpdate = (id_user) => {

        //::todo DESACTIVAR ESTA OPCION SI LOS USUARIOS NO USAN PAGINACION Y BUSQUEDA
        // let findUser = listUsers.find(x => x.id_user === parseInt(id_user));

        let findUser = listUsersSearch.find(x => x.id_user === parseInt(id_user));
        //console.log(findUser);

        if (findUser) {

            let listRolesUser = [];

            if (typeof findUser.name_roles === "string") {
                listRolesUser = [];
            }
            else {
                findUser.ids_roles.forEach((obj, index) => {//lo modifique por array_roles este estaba


                    listRolesUser.push({
                        value: obj,
                        label: findUser.name_roles[index]
                    })
                });
            }

            handleUpdateValues({
                name_user: findUser.name,
                listRoles: listRolesUser
            });

            setUserId(id_user);
            handleGetRoles();
            setMethodUser("update_roles");
            setModalTitle("Editar usuario");
            toggleUser();
        }
    }

    const handleListSectionsCard = (id_user) => {
        let findUser = listUsersSearch.find(x => x.id_user === parseInt(id_user));
        if (findUser) {
            let listSectionsUser = [];
            findUser.ids_sections.forEach((section, indexSection) => {
                if (findUser.sections[indexSection] !== undefined) {
                    //console.log(indexSection);
                    listSectionsUser.push({
                        value: section,
                        label: findUser.sections[indexSection],
                    });
                }
            });

            handleUpdateValues({
                listSections: listSectionsUser
            });
            handleGetSections();
            setUserId(id_user);
        }
    }

    const [listDepartament, setListDepartament] = useState([])
    const [listDepa, setListDepa] = useState([])

    const getCardCode = async (id_user) => {
        let _find = formUser.card_code
        let listTmp = await handleRequest("GET", `AccessUser/${id_user}`);
        console.log('listTmp: aca ', listTmp)
        if (listTmp === null) {
            createSweet("danger", "Advertencia", "Tarjeta  sin crear");
        }
        else {
            let data1 = data;
            console.log(data);

            if (listTmp && listTmp.length > 0) {
                const data = listTmp[0];
                const dataSection = new Set();
                const uniqueSectionsMap = {};
                const dataDepartament = []
                const listTmpDepartament = []


                for (let id of data.id_section) {
                    const section1 = data1.find(sec => sec.id_section === id);
                    if (section1 && !uniqueSectionsMap[section1.id_section]) {
                        uniqueSectionsMap[section1.id_section] = true; // Marcar la sección como única
                        dataSection.add({
                            id_section: section1.id_section,
                            name: section1.label,
                            checkSection: true
                        });
                    }
                }

                data1.forEach(section => {
                    if (!uniqueSectionsMap[section.id_section]) {
                        dataSection.add({
                            id_section: section.id_section,
                            name: section.label,
                            checkSection: false
                        });
                    }
                });

                const uniqueSections = Array.from(dataSection);
                console.log(uniqueSections);
                sectionSet(uniqueSections);

                // data1.forEach(section => {
                //     if (!dataSection.find(sec => sec.id_section === section.id_section)) {
                //         dataSection.push({
                //             id_section: section.id_section,
                //             name: section.label,
                //             checkSection: false
                //         });
                //     }
                // });

                departament.forEach(departament => {
                    listTmpDepartament.push({
                        value: departament.id_departament,
                        label: departament.name
                    })
                })

                const listDepartament = [];
                const idDepartments = listTmp[0].id_departament; // Obtener el valor de id_departaments de la respuesta de la API
                console.log(idDepartments)
                // Verificar si idDepartments es un array
                if (Array.isArray(idDepartments)) {
                    idDepartments.forEach(id => {
                        const newDepartament = departament.find(departament => departament.id_departament === id);
                        console.log(newDepartament);
                        if (newDepartament) {
                            listDepartament.push({
                                value: newDepartament.id_departament,
                                label: newDepartament.name
                            });
                        }
                    });
                } else {
                    const newDepartament = departament.find(departament => departament.id_departament === idDepartments);
                    console.log(newDepartament);
                    if (newDepartament) {
                        listDepartament.push({
                            value: newDepartament.id_departament,
                            label: newDepartament.name
                        });
                    }
                }

                console.log(listDepartament);
                setListDepa(listDepartament);
                //sectionSet(dataSection)

                const departament1 = departament.find(dep => dep.id_departament === data.id_departament[0]);
                if (departament1) {
                    dataDepartament.push({
                        value: departament1.id_departament,
                        label: departament1.name
                    });
                }

                console.log(data.id_departament)
                handleUpdateValues({
                    name_user: data.name_user,
                    listRoles: data.listRoles,
                    listSection: data.id_section,
                    card_code: data.card_code,
                    id_departaments: listDepartament,
                    //id_departaments: data.id_departament && data.id_departament.length > 0 ? data.id_departament[0] : null,
                    listSections: dataSection
                });

                // handleUpdateValuesT({
                //     id_departament: departament1 ? departament1.id_departament : null,
                // });

                setListDepa([...listDepartament]);
                sectionSet([...dataSection]);
            }
        }
    }



    // const getCardCode = async (id_user) => {
    //     let _find = formUser.card_code
    //     let listTmp = await handleRequest("GET", `AccessUser/${id_user}`);
    //     console.log('listTmp: ',listTmp)
    //     if (listTmp === null) {
    //         createSweet("danger", "Advertencia", "Tarjeta  sin crear");
    //     }
    //     else {
    //         let data1 = data;
    //         console.log(data);

    //         if (listTmp && listTmp.length > 0) {
    //             const data = listTmp[0];
    //             const dataSection = [];
    //             const dataDepartament = []
    //             const listTmpDepartament = []


    //             for (let id of data.id_section) {
    //                 const section1 = data1.find(sec => sec.id_section === id);
    //                 if (section1) {
    //                     dataSection.push({
    //                         id_section: section1.id_section,
    //                         name: section1.label,
    //                         checkSection: true
    //                     });
    //                 }
    //             }

    //             data1.forEach(section => {
    //                 if (!dataSection.find(sec => sec.id_section === section.id_section)) {
    //                     dataSection.push({
    //                         id_section: section.id_section,
    //                         name: section.label,
    //                         checkSection: false
    //                     });
    //                 }
    //             });

    //             departament.forEach(departament => {
    //                 listTmpDepartament.push({
    //                     value: departament.id_departament,
    //                     label: departament.name
    //                 })
    //             })

    //             sectionSet(dataSection)

    //             const departament1 = departament.find(dep => dep.id_departament === data.id_departament[0]);
    //             if (departament1) {
    //                 dataDepartament.push({
    //                     value: departament1.id_departament,
    //                     label: departament1.name
    //                 });
    //             }

    //             console.log(data.id_departament)
    //             handleUpdateValues({
    //                 name_user: data.name_user,
    //                 listRoles: data.listRoles,
    //                 listSection: data.id_section,
    //                 card_code: data.card_code,
    //                 id_departaments: data.id_departament,
    //                 //id_departaments: data.id_departament && data.id_departament.length > 0 ? data.id_departament[0] : null,
    //                 listSections: dataSection
    //             });

    //             handleUpdateValuesT({
    //                 id_departament: departament1 ? departament1.id_departament : null,
    //             });

    //             setDepartament([...dataDepartament]);
    //             sectionSet([...dataSection]);
    //         }
    //     }
    // }

    const saveCardCode = async (e) => {
        let aux = e.preventDefault();
        console.log(aux)

        let findUser = listUsersSearch.find(x => x.id_user === parseInt(userId));
        let id = findUser.id_user;

        let idsRoles = [];
        section.forEach(obj => {
            if (obj.checkSection === true) {
                idsRoles.push(obj.id_section)
            }
        })
        setLoading(true);

        let ids_departaments = [];

        id_departaments.forEach(ids => {
            ids_departaments.push(ids.value)
        })

        let id_departamento = [parseInt(id_departament)];
        let newForm = {
            "id_user": id,
            "card_code": card_code,
            "id_section": idsRoles,
            //"id_departament": id_departamento,
            "id_departament": ids_departaments,
        }
        console.log('newForm: ', newForm)
        let listTmp = await handleRequest("POST", "AccessUser", "Tarjeta Autorizada", newForm);
        console.log('1.- ', listTmp)
        if (listTmp !== null) {
            createSweet("create", "success", "Tarjeta creada");
        } else {
            setLoading(false);
        }

        handleUpdateValues({
            "id_user": 0,
            "card_code": "",
            "listSections": [],
            "id_departaments": [],
        })

        setLoading(false);
        setListDepa([]);
        sectionSet([])
        toggleCard();

    }

    const saveUpdatePatient = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (handleValidationsUser()) {
            setLoading(false);
            return;
        }

        let ids_roles = []; //listRoles

        formUser.listRoles.forEach(obj => {
            ids_roles.push(obj.value);
        });

        let findUser = listUsersSearch.find(x => x.id_user === parseInt(userId));

        let body = {
            id_user: userId,
            ids_roles: ids_roles
        };

        let listTmp = await handleRequest("PUT", 'Users/AssingRoles', 'Roles', body);

        if (listTmp !== null) {
            //console.log("******si paso mi metodo para actualizar****");
            setUserId(0);
            setLoading(false);
            toggleUser();
            handleGetUsersRoles();
            createSweet("update", "info", "Usuario actualizado");
        }
    }

    const handleSearchUser = (e) => {
        let busqueda = e.target.value;
        let newMethod = [];
        console.log(listUsersSearch)
        listUsersSearch.forEach(obj => {
            let search_by_name = obj.name.toUpperCase();
            let search_by_username = obj.username.toUpperCase();

            if ((search_by_name.indexOf(busqueda.toUpperCase()) > -1) || (search_by_username.indexOf(busqueda.toUpperCase()) > -1)) {
                obj.visibleTable = true;
                newMethod.push(obj);
            }
        });

        setListUsers(newMethod);
    }

    //#endregion USERS METHOD

    //#region SECTIONS
    const [imagen, setImagen] = useState("")

    const handleListSection = (id_user) => {
        console.log('1111')
        let findUser = listUsersSearch.find(x => x.id_user === parseInt(id_user));
        console.log(findUser)
        if (findUser) {
            let listSectionsUser = [];
            findUser.ids_sections.forEach((section, indexSection) => {
                if (findUser.sections[indexSection] !== undefined) {
                    //console.log(indexSection);
                    listSectionsUser.push({
                        value: section,
                        label: findUser.sections[indexSection],
                    });
                }
            });

            handleUpdateValues({
                name_user: findUser.name,
                listSection: listSectionsUser
            });

            setTypeModal("typeSection");
            setModalTitleSection("Secciones/Áreas")
            toggleSection();
            getSection(); toggleSection();
            getSection();
            getSection();
            setSelected([]);
            setId(id_user);
            handleGetSections();
            setUserId(id_user);
        }
    }

    const [check, setCheck] = useState('false')

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        console.log("ischecked ", isChecked);
        setCheck(isChecked);
        checkbox(isChecked);
    };

    const checkbox = async (isChecked) => {
        if (isChecked) {
            //console.log('El checkbox está marcado');
            getDepartaments()
        } else {
        }
    };

    const handleListCard = (id_user) => {
        setTypeCard("typeCard");
        //console.log('id_user ', id_user)
        let findUser = listUsersSearch.find(x => x.id_user === parseInt(id_user));

        handleSectionUser(id_user);
        setModalTitleCard("Tarjeta de Trazabilidad")
        toggleCard();
        setId(id_user);
        setUserId(id_user);
        getCardCode(id_user)
        getDepartaments()
    }


    useEffect(() => {
        getDepartaments()
        getSection()
    }, [history.location]);

    //DEPARTAMENT
    const [formValuesT, handleInputChangeT, , handleUpdateValuesT, handleSelectValuesT] = useForm({
        id_area: "",
        id_departament: "",
        userName: "",
        password: "",
        card_codes: ""
    });

    const { id_area, id_departament, userName, password, card_codes } = formValuesT;

    const handleDepartamento = () => {
        handleUpdateValuesT({
            id_area: 0,
            id_departament: 0,
            userName: "",
            password: "",
            card_codes: ""
        })
    }

    const [departament, setDepartament] = useState([]);
    //const [flag, setFlag] = useState(false);

    const getDepartaments = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListDepartaments = [];
        let listTmpDepartament = []

        const respuesta = await sendRequest(requestOptions, "Departaments/List");
        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value) => {
                    ListDepartaments.push({
                        id_departament: value.id_departament,
                        id_area: value.id_area,
                        name: value.name,
                    });
                });
                setDepartament(ListDepartaments);
                respuesta.data.forEach((value) => {
                    listTmpDepartament.push({
                        value: value.id_departament,
                        label: value.name
                    })
                })
                setListDepartament(listTmpDepartament)
            }
            else {
                setDepartament([]);
            }
        }
    }


    //SECTION
    const [section, sectionSet] = useState([])
    const [tree, setTree] = useState([])
    const treeviewRef = useRef(null);
    const [processes, setProcesses] = useState([]);

    // const onRenderItem = (item, treeview) => {
    //     return(
    //         <div className="treeview-animated-element">
    //             <i className="icofont icofont-microscope-alt f-12"></i> {item.name}
    //         </div>
    //     )
    // }

    const onRenderItem = (item, treeview) => {
        return (
            <div className="treeview-animated-element">
                <i className="icofont icofont-microscope-alt f-12"></i> {item.name}
            </div>
        )
    }

    const getProcessesInSection = async (id_section) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Processes/GetProccessInSection/" + id_section + "/true");
        console.log('respuesta.data ', respuesta.data)
        if (respuesta.code === 200) {
            respuesta.data.map(element => {
                element.label = element.name + "[" + element.code + "]";
                element.value = element.id_process;
                element.id_section = id_section
            });
            return setProcesses(respuesta.data);
        } else {
            validarSesion(history, respuesta.code, getProcessesInSection);
        }
    }

    const changeTree = (e) => {
        // Cambiar el estado state de 1 a 2 y el valor isLeaf de true a false
        const updatedTree = tree.map(item => {
            if (item.id_section === e.id_section) {
                return {
                    ...item,
                    state: 2,
                    isLeaf: false
                };
            }
            return item;
        });

        setTree(updatedTree);
        treeSection(e)
        // Llamar a getProcessesInSection solo si es necesario, dependiendo de la lógica de tu aplicación
        // getProcessesInSection(e.id_section);
    }

    const treeSection = async (e) => {
        let a = e
        const api = treeviewRef.current.api
        const items = api.getItems();
        let array = [];

        items.forEach(element => {
            if (element.id_section !== undefined) {
                let pro_aux = [];
                let id = true;
                let checkParent = 1;
                let auxiliar = processes;

                auxiliar.forEach(p => {
                    pro_aux.push({
                        "id_process": p.id_process,
                        "isLeaf": p.id_section,
                        "state": p.id_section === true ? 1 : 2,
                        "name": p.name,
                    })
                })

                array.push({
                    //"children": pro_aux,
                    "id_section": element.id_section,
                    "name": element.name,
                    "isLeaf": id,
                    "state": checkParent
                })
            }
        })

        setTree(array);
        console.log('array: ', array)
    }

    // const handleSectionUser = (id_user) => {
    //     console.log(id_user)
    //     handleGetSections();
    //     getSection();

    //     let findUser = listUsersSearch.find(x => x.id_user === parseInt(id_user));
    //     let sectionsFound = [];

    //     const userSectionsArray = Object.values(findUser.sections); 
    //     console.log('userSectionsArray: ',userSectionsArray)
    //     data.forEach(section => {
    //         if (data != undefined) {
    //             sectionsFound.push({
    //                 id_section: section.id_section,
    //                 name: section.label,
    //             });
    //         }
    //     });

    //     sectionSet(sectionsFound);
    //     console.log('section ', sectionsFound)
    // }


    const [checkend, setCheckend] = useState([false])

    const handleCheckChange = (e, id_section) => {
        const checked = e.target.checked;
        console.log(checked);
        console.log(listSections);

        // Convertir el conjunto (Set) a un array utilizando el operador de propagación (...)
        const listSectionsArray = [...listSections];

        const updatedSections = listSectionsArray.map(section => {
            if (section.id_section === id_section) {
                return {
                    ...section,
                    checkSection: checked
                };
            }
            return section;
        });

        sectionSet(updatedSections);

        handleUpdateValues({
            card_code: card_code,
            listSections: updatedSections,
            id_departaments: id_departaments,
        });
    };


    // const handleCheckChange = (e, id_section) => {
    //     const checked = e.target.checked;
    //     console.log(checkend)
    //     console.log(listSections)

    //         const updatedSections = listSections.map(section => {
    //             if (section.id_section === id_section) {
    //                 return {
    //                     ...section,
    //                     checkSection: checked
    //                 };
    //             }
    //             return section;
    //         });

    //         sectionSet(updatedSections);

    //         handleUpdateValues({
    //             card_code: card_code,
    //             listSections: updatedSections
    //         });

    // };


    const handleSectionUser = (id_user) => {
        console.log(id_user);
        handleGetSections();
        getSection();
        let findUser = listUsersSearch.find(x => x.id_user === parseInt(id_user));
        console.log(findUser)
        let sectionsFound = [];
        const userSectionsArray = Object.values(findUser.sections);
        console.log('userSectionsArray: ', userSectionsArray);
        data.forEach(section => {
            if (data != undefined) {
                const count = userSectionsArray.filter(name => name === section.label).length;
                sectionsFound.push({
                    id_section: section.id_section,
                    name: section.label,
                    checkSection: count === 1 ? true : false
                });
            }
        });

        sectionSet(sectionsFound);
        console.log('section ', sectionsFound);
        handleUpdateValues({ listSections: sectionsFound }); // Actualiza el estado listSections
    };




    // const handleSectionUser = (id_user) => {
    //     console.log(id_user)
    //     handleGetSections();
    //     getSection();

    //     let findUser = listUsersSearch.find(x => x.id_user === parseInt(id_user));
    //     let sectionsFound = [];

    //     const userSectionsArray = Object.values(findUser.sections);
    //     console.log('userSectionsArray: ', userSectionsArray);
    //     data.forEach(section => {
    //         //console.log(section.label)
    //         if (data != undefined) {
    //             const count = userSectionsArray.filter(name => name === section.label).length;
    //             //console.log(count)
    //             sectionsFound.push({
    //                 id_section: section.id_section,
    //                 name: section.label,
    //                 checkSection: count === 1 ? true : false
    //             });
    //             //console.log('1 ', sectionsFound);

    //         }
    //     });
    //     sectionSet(sectionsFound);
    //     console.log('section ', sectionsFound);

    //     sectionsFound.forEach(obj => {
    //         if(obj.checkSection === true){
    //             listSections.push(obj.id_section);
    //             console.log('entré')
    //         }
    //     })

    //     handleUpdateValues({
    //         listSections: sectionsFound
    //     });
    // }


    const [listSect, setListSect] = useState([]);
    const [sectionSelected, setSectionSelected] = useState([]);

    const handleGetSections = async () => {
        let listTmp = await handleRequest("GET", "Section/List/false", "Sectionss");
        console.log('listTmp: ', listTmp)
        if (listTmp !== null && listTmp.length > 0) {
            let list = [];
            let arreglo = [];

            listTmp.forEach(obj => {
                list.push({
                    value: obj.id_section,
                    label: obj.name,
                    check: true,
                    ...obj
                });
                arreglo.push(obj.id_section);
            });
            setListSect(list);
            setSectionSelected(arreglo)
        }
    }


    // const [selectedSections, setselectedSections] = useState([]);
    // const [sectiondefault,setSectiondefault]=useState([]);

    // const handleGetSections2 = async () => {
    //     let listTmp = await handleRequest("GET", "Section/List/false", "Sectionss");

    //     if (listTmp !== null && listTmp.length > 0) {

    //         let secciones = [];
    //         let sectionmultiselect=[];

    //         let list = [];
    //         listTmp.forEach(obj => {
    //             list.push({
    //                 value: obj.id_section,
    //                 label: obj.name
    //             });
    //         });
    //         setListSect(list);
    //     }
    // }

    const [selected, setSelected] = useState([]);

    const onChange = (selected) => {
        setSelected(selected)
    };

    const [data, setData] = useState([]);


    const getSection = async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Section/List/false");
        //console.log(respuesta)
        if (respuesta.code === 200) {
            let array = []

            respuesta.data.forEach(element => {

                if (element.is_imagenology) {
                    setImagen("true")

                }
                array.push({
                    "value": element.id_section,//ID
                    "label": element.name,
                    "id": element.id_section,//ID PRECODTEXT,
                    "id_section": element.id_section,
                    //"id_type_precoded_text": element.id_type_precoded_text
                })
            });

            setData(array);
        }
        else if (respuesta.code === 401) {
            // validarSesion(history, respuesta.code, getSection);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Exámen", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Exámen", respuesta.data.msg);
        }
    }

    const [id, setId] = useState(0)

    // {
    //     "id_user": 0,
    //     "ids_sections": [
    //       0
    //     ]
    //   }


    const saveUpdateSections = async (e) => {
        e.preventDefault();
        setLoading(true);

        let array2 = []

        formUser.listSection.forEach(obj => {
            array2.push(obj.value);
        });

        let body = {
            id_user: userId,
            ids_sections: array2

        };


        let listTmp = await handleRequest("PUT", 'Users/AssingSections', 'Sections', body);

        if (listTmp !== null) {
            //console.log("pasa");

            setUserId(0);
            setLoading(false);
            toggleSection();
            handleGetUsersRoles();
            createSweet("update", "info", "Secciones actualizadas");
        }
    }

    //#endregion SECTIONS

    //#region Modal Section And Rol

    const [modalTitleSection2, setModalTitleSection2] = useState(false);
    const [modalSection2, setModalSection2] = useState(false);
    const [typeModalSection, setTypeModalSection] = useState("viewSection");
    const [dataSections, setDataSections] = useState([]);


    const toggleSection2 = () => {
        setModalSection2(!modalSection2)
    }


    const handleListSections = (id_user) => {

        let findUser = listUsersSearch.find(x => x.id_user === parseInt(id_user));
        console.log(findUser)
        setDataSections(findUser.sections)
        console.log(dataSections)
        setTypeModalSection("viewSection");
        setModalTitleSection2("Secciones");
        toggleSection2();
    }



    //#end region Modal Section And Rol



    //#region PAGINATION             
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(listUsers.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;
        startItems = endItems - numberItems;

        let listTmp = [];

        listUsers.forEach((data, key) => {
            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listTmp.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setListUsers(listTmp);

    }, [totalPageCount, currentPage]);

    //#endregion PAGINATION             

    //#region USERS USE EFFECT

    useEffect(() => {
        handleGetUsersRoles();
    }, [handleGetUsersRoles]);


    //#endregion USERS USE EFFECT

    //#endregion USERS



    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    const handleValidationsUser = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formUser.listRoles.length === 0) {
            newValidations = {
                ...newValidations,
                listRoles_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                listRoles_valid: false
            }
        }

        setValidationsUser(newValidations);
        return statusValidation;
    }

    return {
        modalTitle, modalUser, methodUser, toggleUser, modalCard, toggleCard, modalTitleCard, typeCard,
        checkbox, check, handleCheckboxChange, handleInputChangeT, id_departament, departament,
        handleSectionUser, section, treeviewRef, changeTree, tree, onRenderItem, processes,
        saveCardCode, handleListSectionsCard, card_code, handleInputChange, checkSection, handleCheckChange,
        listDepartament, formUser, id_departaments, listDepa,

        //USERS MODEL 
        listUsers, listRol, loading,
        name_user, listRoles, handleInputChange, handleSelectValues,
        validationsUser,
        //USERS METHODS
        handleUpdate, saveUpdatePatient,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        handleSearchUser,
        //SECTION   
        handleListSection, handleListCard,
        modalTitleSection, modalSection, toggleSection, typeModal,
        selected, onChange, data, imagen,
        saveUpdateSections,
        //sections
        listSection, listSect,
        //viewa
        modalTitleSection2, modalSection2, typeModalSection, handleListSections, toggleSection2, dataSections,
        sectionSelected

    }
}